import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { decode } from 'he'
import {
  BackArrowButton,
  ControlButton,
  IColumnElement,
  IRowElement,
  PaginationPanel,
  SearchInput,
  SmartList,
  TooltipMenu,
  ListButton,
  Icons,
  ListItemCounter
} from '@doseme/cohesive-ui'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import { getPageCount, paginate } from '../../../../utils/pagination'
import { useAdminHospitalListStore, useAuthStore, useClinicianStore } from '../../../../hooks/useStore'
import { IAdminHospitalListItem } from '../../../../store/Admin/AdminHospitalList/types'
import { sortAlpha } from '../../../../utils/smartListUtils'
import { handleBackButton } from '../../../../utils/navigation'
import { searchHospitals, hospitalSettingsColumns } from './utils'
import { hospitalActionData, itemsPerPage } from './constants'

import './index.scss'

export const HospitalList: React.FC = observer((props) => {
  const [searchText, setSearchText] = useState('')
  const [sortColIndex, setSortColIndex] = useState(0)
  const [sortColAscending, setSortColAscending] = useState(true)
  const [sortedList, setSortedList] = useState<IRowElement[] | null>(null)
  const [totalPages, setTotalPages] = useState<number>(1)
  const [currentPage, updateCurrentPage] = useState(1)

  const authStore = useAuthStore()
  const adminHospitalListStore = useAdminHospitalListStore()
  const clinicianStore = useClinicianStore()

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    adminHospitalListStore.fetchAdminHospitals()
  }, [])

  useEffect(() => {
    if (clinicianStore.loadState !== 'loaded' && authStore.auth) {
      clinicianStore.fetchClinician(authStore.auth.attributes.clinicianId)
    }
  }, [])

  useEffect(() => {
    if (adminHospitalListStore.loadState === 'loaded' && adminHospitalListStore.adminHospitals) {
      const searchedHospitals: IAdminHospitalListItem[] = searchHospitals(
        searchText,
        adminHospitalListStore.adminHospitals
      )
      setTotalPages(getPageCount(searchedHospitals.length, itemsPerPage))
      const formattedHospitals = formatHospitals(searchedHospitals)
      const sortedSearchedFormattedHospitals = sortAlpha(formattedHospitals, sortColIndex, sortColAscending)
      setSortedList(sortedSearchedFormattedHospitals)
    }
  }, [adminHospitalListStore.loadState, searchText, sortColIndex, sortColAscending])

  const handleCreateNewHospital = () => {
    navigate('./new')
  }

  const handleDuplicateHospital = (hospitalId: string) => {
    navigate(`./${hospitalId}/duplicate`)
  }

  const handleHospitalDetailLink = (hospitalId: string) => {
    navigate(`./${hospitalId}`)
  }

  const handleSort = (colIndex: number, ascending: boolean) => {
    setSortColIndex(colIndex)
    setSortColAscending(ascending)
  }

  const handleSearch = (searchText: string) => {
    setSearchText(searchText)
    updateCurrentPage(1)
  }

  const formatHospitals = (hospitals: IAdminHospitalListItem[]): IRowElement[] => {
    return hospitals.map((hospital, key) => {
      const recordColumns: IColumnElement[] = [
        {
          name: 'Name',
          link: () => handleHospitalDetailLink(hospital.id),
          text: decode(hospital.attributes.name)
        }
      ]

      if (clinicianStore.clinician?.attributes.isSuperAdmin) {
        recordColumns.push(
          {
            name: 'ID',
            element: <span>{hospital.id}</span>,
            text: hospital.id,
            onClick: () => handleHospitalDetailLink(hospital.id)
          }
        )
      }

      recordColumns.push(
        {
          name: 'Timezone',
          element: <span>{hospital.attributes.timezone}</span>,
          text: hospital.attributes.timezone,
          onClick: () => handleHospitalDetailLink(hospital.id)
        }
      )

      if (clinicianStore.clinician?.attributes.isSuperAdmin) {
        recordColumns.push({
          name: '',
          element: (
            <div className='hospital-list-action-button' data-testid='ellipsis-icon'>
              <TooltipMenu
                button={
                  <ListButton size='cm'>
                    <Icons.Ellipsis />
                  </ListButton>
                }
                data={hospitalActionData(hospital, handleDuplicateHospital)}
                alignRight={true}
                chevronOffset={10}
              />
            </div>
          )
        })
      }

      return {
        id: hospital.id,
        columns: recordColumns
      }
    })
  }

  return (
    <div data-testid='hospital-list' className='co-hospital-list-page'>
      <div className='hospital-list-heading'>
        <div className='hospital-list-back-btn'>
          <BackArrowButton data-testid='back-btn' onClick={() => handleBackButton('../../.', navigate, location)} />
        </div>
        <div className='hospital-list-title'>Hospitals</div>
        {clinicianStore.clinician?.attributes.isSuperAdmin && (
          <div className='create-new-hospital-btn'>
            <ControlButton icon={faPlus} onClick={handleCreateNewHospital}>
              Create new hospital
            </ControlButton>
          </div>
        )}
      </div>
      <div className='hospital-list-content-panel'>
        <div className='hospital-list-search-bar-container'>
          <div className='hospital-list-search-bar'>
            <SearchInput
              value={searchText}
              onChange={handleSearch}
              width={360}
              borderRadius={12}
              placeholder='Search list...'
            />
          </div>
        </div>
        <div className='hospital-list-list'>
          <SmartList
            cols={hospitalSettingsColumns(handleSort, !!clinicianStore.clinician?.attributes.isSuperAdmin)}
            data={sortedList ? paginate(sortedList, { currentPage, itemsPerPage }) : []}
            defaultSortColumn='Name'
            defaultSortDirection='asc'
            loading={adminHospitalListStore.loadState === 'loading'}
            textIfEmpty='No data to display'
            minRowsToShow={itemsPerPage}
          />
        </div>
        <div className='hospital-list-pagination-panel-outer'>
          <div className='hold-space-for-pagination'></div>
          <ListItemCounter
            listLength={sortedList?.length || null}
            pageLength={itemsPerPage}
            currentPage={currentPage}
            isLoading={['loading', 'initial'].includes(adminHospitalListStore.loadState)}
          />
          <div className='hospital-list-pagination-panel'>
            <PaginationPanel currentPage={currentPage} totalPages={totalPages} onPageChange={updateCurrentPage} />
          </div>
        </div>
      </div>
    </div>
  )
})

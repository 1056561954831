import { IReleaseNote } from '@doseme/cohesive-ui'

/**
 * Release notes are to be added here
 */

export const releaseNotes: IReleaseNote[] = [
  {
    versionNumber: '2.6.6',
    title: 'Enhancing visualization of the dosing profile plot by including more datapoints',
    description: 'Excluded drug levels are now visible in-context on the dosing profile plot. Any drug level that ' +
      'has been excluded from calculations will still display on the dosing profile using a new icon, to assist ' +
      'with assessing the patient\'s response.',
    date: new Date(2024, 11, 1)
  },
  {
    versionNumber: '2.6.0',
    title: 'Enhancing your patient care experience with updates to the minimum height constraints and to eCrCl calculations',
    description: (
      <ul>
        <li>
          With this release, we have updated the minimum height restriction for all drug models supporting the adult
          cohort. The minimum adult patient height is now 145cm, previously 150cm.
        </li>
        <li>
          Also, for pediatric patients, we have replaced the eCrCl Schwartz formula with Bedside Schwartz in the
          Dosing Indicators section on the dose recommendation page. We have also added an informative hover over
          tooltip to indicate the eCrCl calculation method used.
        </li>
      </ul>
    ),
    date: new Date(2024, 8, 26)
  },
  {
    versionNumber: '2.4.0',
    title: 'Improvements to intuitive dosing interval when adding historical doses',
    description: 'With this release we have made several usability improvements to the functionality for adding ' +
      'historical doses, making manual data entry even easier. In the "Add doses" window, DoseMeRx will now pre-fill the ' +
      'date and time of each dose based on the interval from prior doses as you add them. The initial dose time will ' +
      'still be based on previously recorded doses, or the default dosing interval setting when no recorded doses ' +
      'are found.',
    date: new Date(2024, 7, 15)
  },
  {
    versionNumber: '2.3.0',
    title: 'Improved filters for the patients list',
    description: 'The new interface in DoseMeRx now brings an improved version of the patient filters, so you can ' +
      'quickly and easily assess groups of patients with similar characteristics. Filter the patients list by unit ' +
      '(ward), treating clinician, or drug – and you can now even filter by individual drug model, so you can easily ' +
      'see patients that fall under particular cohorts.',
    date: new Date(2024, 6, 23),
    display: window.env.VENDOR_MODE === 'standalone'
  },
  {
    versionNumber: '2.0.0',
    title: 'Welcome to the new and improved DoseMeRx interface!',
    description: 'Introducing the new and improved version of DoseMeRx. With improvements right across the ' +
      'interface, these updates offer significant usability and time-saving enhancements, while providing the same ' +
      'trusted dosing confidence. Along with the updated interface, this update also brings a host of helpful new ' +
      'features, including the alternative dosing matrix, and the notes & activity log.',
    date: new Date(2024, 5, 4),
    display: window.env.VENDOR_MODE === 'standalone'
  },
  // Pre standalone release notes
  ...(window.env.VENDOR_MODE !== 'standalone' ? [
    {
      versionNumber: '1.11.0',
      title: 'Model suggestion for creating new manual courses',
      description: 'DoseMeRx can now suggest the most suitable model when creating a new manual course, based on the patient\'s ' +
        'demographic information where applicable. All other applicable models are also displayed along with suggestions to allow ' +
        'you to have the ability to select the most appropriate model based on your clinical judgment.',
      date: new Date(2023, 9, 3)
    },
    {
      versionNumber: '1.10.12',
      title: 'Model fit indicator now available for the vancomycin pediatric models',
      description: 'DoseMeRx now includes a visual representation of how well the model is fitting to your pediatric ' +
        'vancomycin patient\'s data, with the model fit indicator.',
      date: new Date(2023, 7, 17)
    },
    {
      versionNumber: '1.10.10',
      title: 'Changes to the delete course functionality',
      description: 'This release includes changes to the delete course functionality to minimize data loss. Now, you ' +
        'will be unable to delete a course if it contains data that would be unretrievable, such as older EHR data ' +
        'or dosing reports.',
      date: new Date(2023, 6, 18),
      display: (window.env.VENDOR_MODE === 'epic' || window.env.VENDOR_MODE === 'cerner')
    },
    {
      versionNumber: '1.10.9',
      title: 'Displaying most recent course data on the dosing plot',
      description: 'With this release, we have updated the dosing plot by reducing the amount of data that is initially ' +
        'displayed on the plot in order to increase performance and improve readability. Upon initial load of the ' +
        'course page, DoseMeRx will now only show recent course data on the dosing plot. However, the complete ' +
        'course history can still be viewed by adjusting the slider below the plot to modify the visible timeline ' +
        'as desired.',
      date: new Date(2023, 6, 4)
    },
    {
      versionNumber: '1.10.3',
      title: 'Dose markers on the dosing plot and enforced course variables settings',
      description: (
        <ul>
          <li>
            With this release you will now see dose markers on the dosing plot, making it easier to identify administered
            doses and review how a patient is responding to treatment
          </li>
          <li>
            We are also now enforcing review for a number of important course variables settings before any doses can be simulated.
            This will ensure that these parameters, which have a significant effect on the dose recommendation, are manually reviewed and/or updated,
            leading to more accurate dose simulations as the model is making less assumptions.
          </li>
        </ul>
      ),
      date: new Date(2023, 4, 3)
    },
    {
      versionNumber: '1.10.0',
      title: 'Multi-select actions for recorded course data',
      description: 'You now have the ability to select multiple course records (doses and/or observations), and ' +
        'exclude or include all selected records at once.',
      date: new Date(2023, 2, 14)
    },
    {
      versionNumber: '1.9.0',
      title: 'Enhanced course preview panel, view MIC value on the dosing plot, and course variable descriptions',
      description: (
        <ul>
          <li>
            We've improved the course preview panel, by showing the total number of doses and observations recorded
            underneath each of the 'most recent' tables – click 'view all' to take you directly to the relevant section in the Recorded Course Data panel
          </li>
          <li>
            New: When simulating a dose in a course with the calculation type involving MIC, you can now see a
            visualization of the MIC value directly on the dosing plot
          </li>
          <li>
            Description is now available for relevant course variables via a hoverable information tooltip
          </li>
        </ul>
      ),
      date: new Date(2023, 1, 28)
    },
    {
      versionNumber: '1.8.0',
      title: 'Ability to view past reports',
      description: 'Bringing you the ability to view saved reports right inside DoseMeRx, ' +
        'via the "Notes and Activity log" panel. Any dosing reports saved as of now will be available to ' +
        'view again by clicking the "view saved report" button under the message in the activity log.',
      date: new Date(2023, 1, 16)
    },
    {
      versionNumber: '1.7.0',
      title: 'Ability to add multiple doses and observations & enhancements to the "Notes and Activity log" panel',
      description: (
        <ul>
          <li>
            In this release, we have added the ability to add multiple doses and multiple observations from a single step.
          </li>
          <li>
            The release also includes an enhanced visual representation of the "Notes and Activity log" section.
            Tabs separating activity logs, comments and reports are now added to the panel.
          </li>
        </ul>
      ),
      date: new Date(2023, 0, 18)
    },
    {
      versionNumber: '1.6.3',
      title: 'New pediatric vancomycin model + New alternative dosing matrix',
      description: (
        <ul>
          <li>
            New and improved: Alternative Dosing Matrix. We’ve redesigned the preview higher/lower dose feature; now you
            can see a matrix of multiple alternative dosing options from the individualized recommendation, both by dose
            amount and interval. Available for vancomycin (non-HD) models only.
          </li>
          <li>
            This release also includes a new validated pediatric vancomycin model (Smit et al 2021), available now for
            anyone who already has access to the existing pediatric vancomycin model.
          </li>
        </ul>
      ),
      date: new Date(2022, 11, 6)
    },
    {
      versionNumber: '1.6.2',
      title: 'Updates to the "PK Parameters" panel',
      description: 'In this release, we have added volume of distribution at steady state (Vdss) alongside existing ' +
        'volume of distribution (Vd) in the "PK Parameters" panel. Additional information for "Vd" and "Vdss" is ' +
        'available when hovering over the information icon next to the fields.',
      date: new Date(2022, 10, 22)
    },
    {
      versionNumber: '1.6.0',
      title: 'Serum Creatinine Values for Manually Created New Course',
      description: 'In this release, you will now see Serum Creatinine values populated when manually creating a new ' +
        'course. You will no longer need to manually add serum creatinine values after manually creating a course for ' +
        'our patient.',
      date: new Date(2022, 10, 17),
      display: (window.env.VENDOR_MODE === 'epic' || window.env.VENDOR_MODE === 'cerner')
    },
    {
      versionNumber: '1.5.3',
      title: 'Track and Trend Serum Creatinine',
      description: 'In this release, we bring you a visual representation of your patient\'s serum creatinine over ' +
        'time for their course, directly below the dosing plot. In this new dedicated serum creatinine plot you will ' +
        'be able to see the patient\'s baseline serum creatinine (i.e. the first recorded SeCr in the course), as well ' +
        'as the highest and the lowest recorded SeCr levels.',
      date: new Date(2022, 8, 1)
    },
    {
      versionNumber: '1.4.0',
      title: 'Model Fit Indicator & Intuitive Dosing Intervals',
      description: (
        <ul>
          <li>
            DoseMeRx now includes a visual representation of how well the model is fitting to your patient's data, with
            the Model Fit Indicator.
          </li>
          <li>
            In both the ‘Individualized Default Dose’ and ‘Population Dose Recommendation’ panels, DoseMeRx will now use
            intuitive logic to determine the dosing interval, based on prior recorded doses.
          </li>
        </ul>
      ),
      date: new Date(2022, 5, 23)
    },
    {
      versionNumber: '1.3.3',
      title: '"What\'s New" Section',
      description: 'In this release, we bring you the "What\'s New" section. Here you will see notifications for each ' +
        'new release going forward, containing information about new or updated features, as well as any improvements ' +
        'we make to DoseMeRx.',
      date: new Date(2022, 5, 13)
    }
  ] : [])
]

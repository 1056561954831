import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import {
  BackArrowButton,
  Skeleton,
  Col,
  Container,
  Row,
  ActionButton,
  Icons,
  Tag,
  InfoBubble,
  red100,
  INPUT_GREY
} from '@doseme/cohesive-ui'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import useScreenSize from 'use-screen-size'
import { Tooltip } from 'react-tooltip'
import { toast } from 'react-toastify'

import {
  useDosingRecommendationStore,
  usePatientStore,
  useCourseStore,
  useHospitalStore,
  useAdministrationsStore,
  useObservationsStore,
  useCourseFeaturesStore,
  useApplicableDrugModelsStore,
  useHistoricalSimulationStore,
  useActivityLogStore
} from '../../../../../../hooks/useStore'
import { Header } from '../../../Header'
import { getDrugNameFromDrugModel, getModelNameFromDrugModel } from '../../../../../../utils/drugModels'
import { IModelDoseRecommendation, TModelType } from '../../../../../../store/dosingRecommendation/types'
import { formatToDisplayDate } from '../../../../../../utils/dates'
import { handleBackButton } from '../../../../../../utils/navigation'
import { DosingProfilePanel } from './components/DosingProfilePanel'
import { RecordedCourseDataPanel } from './components/RecordedCourseDataPanel'
import { DrugSwitchModal } from './components/DrugSwitchModal'
import { OtherIndicatorsPanel } from './components/OtherIndicatorsPanel'
import { PKParametersPanel } from './components/PKParametersPanel'
import { CourseFeaturesPanel } from './components/CourseFeaturesPanel'
import { SimulationPanel } from './components/SimulationPanel'
import { ActivityLogs } from './components/ActivityLogs'
import { TCustomType } from './types'
import { DocetaxelSimulationPanel } from './components/DocetaxelSimulationPanel'

import './index.scss'

export const genericModelCalculationErrorMsg =
  'DoseMeRx cannot provide a dose recommendation. Please contact DoseMeRx support for further assistance.'

export const DoseRecommendation: React.FC = observer(() => {
  const patientStore = usePatientStore()
  const courseStore = useCourseStore()
  const hospitalStore = useHospitalStore()
  const administrationsStore = useAdministrationsStore()
  const observationsStore = useObservationsStore()
  const courseFeaturesStore = useCourseFeaturesStore()
  const historicalSimulationStore = useHistoricalSimulationStore()
  const dosingRecommendationStore = useDosingRecommendationStore()
  const applicableDrugModelsStore = useApplicableDrugModelsStore()
  const activityLogStore = useActivityLogStore()

  const [drugModelId, setDrugModelId] = useState<string | null>(null)

  const [isIndividualized, setIndividualized] = useState(false)

  const [showAddAdminModal, setShowAddAdminModal] = useState(false)
  const [showDrugSwitchModal, setShowDrugSwitchModal] = useState(false)
  const [customTypeTab, setCustomTypeTab] = useState<TCustomType>('target')
  const [currentModelView, setCurrentModelView] = useState<TModelType>('indPop')
  const [currentSimulation, setCurrentSimulation] = useState<IModelDoseRecommendation | null>(null)
  const [switchDrugModelDisabled, setSwitchDrugModelDisabled] = useState<boolean>(true)
  const [isModelSuggestionToastShown, setIsModelSuggestionToastShown] = useState<boolean>(false)
  const size = useScreenSize()

  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()

  const patientId = params['patientId']!
  const courseId = params['courseId']!

  // Purge the relevant stores whenever the component dismounts (changed page)
  useEffect(() => {
    return () => {
      courseStore.resetStore()
      historicalSimulationStore.resetStore()
      dosingRecommendationStore.resetStore()
      applicableDrugModelsStore.resetStore()
      administrationsStore.resetStore()
      observationsStore.resetStore()
      courseFeaturesStore.resetStore()
      activityLogStore.resetStore()
      toast.dismiss('model-suggestion-toast')
    }
  }, [])

  useEffect(() => {
    if (patientStore.loadState === 'initial' && patientId) {
      patientStore.fetchPatient(patientId)
    }
  }, [patientId])

  useEffect(() => {
    if (!courseStore.course?.attributes.courseArchived) {
      const newSwitchDrugModelDisabled =
        patientStore.loadState !== 'loaded' ||
        courseStore.loadState !== 'loaded' ||
        !['initial', 'loaded'].includes(applicableDrugModelsStore.loadState) ||
        observationsStore.loadState !== 'loaded'

      if (newSwitchDrugModelDisabled !== switchDrugModelDisabled) {
        setSwitchDrugModelDisabled(newSwitchDrugModelDisabled)
      }
    }
  }, [patientStore.loadState, courseStore.loadState, applicableDrugModelsStore.loadState, observationsStore.loadState])

  useEffect(() => {
    if (courseStore.loadState === 'initial' && courseId) {
      courseStore.fetchCourse(patientId, courseId)
    }
  }, [courseId])

  useEffect(() => {
    if (patientStore.loadState === 'loaded' && courseStore.loadState === 'loaded' && courseStore.course) {
      setDrugModelId(courseStore.course.attributes.drugModel.id)

      // set simulation type for docetaxel
      if (courseStore.isDocetaxelCourse()) {
        setCurrentModelView('customDose')
        setCustomTypeTab('dose')
      }
    }
  }, [patientStore.loadState, courseStore.loadState])

  useEffect(() => {
    if (
      drugModelId &&
      [administrationsStore.loadState, observationsStore.loadState, courseFeaturesStore.loadState].every(
        (x) => x === 'loaded'
      )
    ) {
      historicalSimulationStore.fetchHistoricalDosingRecommendation(patientId, courseId, drugModelId)
    }
  }, [drugModelId, administrationsStore.loadState, observationsStore.loadState, courseFeaturesStore.loadState])

  useEffect(() => {
    if (historicalSimulationStore.loadState === 'loaded') {
      if (
        !isModelSuggestionToastShown &&
        historicalSimulationStore.historicalSimulationData?.attributes.hasBetterFittingModel
      ) {
        setIsModelSuggestionToastShown(true)
        showModelSuggestionToast()
      }

      if (
        isModelSuggestionToastShown &&
        !historicalSimulationStore.historicalSimulationData?.attributes.hasBetterFittingModel
      ) {
        setIsModelSuggestionToastShown(false)
        toast.dismiss('model-suggestion-toast')
      }
    }
  }, [historicalSimulationStore.loadState])

  const CustomToast = () => {
    return (
      <div className='custom-toast-better-model'>
        <div className='mr-2'>
          <Icons.Star width={20} height={20} />
        </div>
        <div>
          We've found another model that may be better suited to this patient.{' '}
          <div className='model-suggestion-link' onClick={() => setShowDrugSwitchModal(true)}>
            See model suggestion{' '}
            <div className='ml-2'>
              <Icons.BackArrowRounded fill='rgb(14, 75, 149)' width={16} height={12} />
            </div>
          </div>
        </div>
      </div>
    )
  }

  const showModelSuggestionToast = () => {
    toast(<CustomToast />, {
      position: 'top-right',
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      autoClose: false,
      className: 'custom-toast-better-model-outer',
      toastId:'model-suggestion-toast'
    })
  }

  if (patientStore.loadState === 'loadError' || (patientStore.loadState === 'loaded' && !patientStore.patient)) {
    if (window.env.APP_MODE === 'standalone') {
      navigate('/patients')

      return null
    }

    throw Error(patientStore.error || 'Not found')
  }

  if (courseStore.loadState === 'loadError' || (courseStore.loadState === 'loaded' && !courseStore.course)) {
    navigate(`/patients/${patientId}`)

    return null
  }

  if (
    [patientStore.loadState, courseStore.loadState].includes('loading') ||
    [patientStore.loadState, courseStore.loadState].includes('initial') ||
    !courseStore.course || !patientStore.patient
  ) {
    return (
      <>
        <Skeleton.PageContent showConnectionStatus={window.env.VENDOR_MODE !== 'standalone'} />
      </>
    )
  }

  const patient = patientStore.patient!
  const course = courseStore.course!

  const courseDoseWindow = (): JSX.Element => {
    if (administrationsStore.loadState === 'loaded') {
      const flDoseDates = administrationsStore.firstLastDoseDates
      if (flDoseDates.first) {
        if (flDoseDates.first === flDoseDates.last) {
          return (
            <div className='doserecommendation-title-first-last-dose'>
              <span className='doserecommendation-bold-title pr-1'>First Dose:</span>
              {formatToDisplayDate(flDoseDates.first, hospitalStore.hospital!.attributes.timezone)}
            </div>
          )
        }

        return (
          <div className='doserecommendation-title-first-last-dose'>
            <span className='doserecommendation-bold-title'>First Dose:</span>
            <span className='pl-1'>
              {formatToDisplayDate(flDoseDates.first, hospitalStore.hospital!.attributes.timezone)}
            </span>
            <span className='pl-2 pr-2'>&mdash;</span>
            <span className='doserecommendation-bold-title'>Last Dose:</span>
            <span className='pl-1'>
              {formatToDisplayDate(flDoseDates.last, hospitalStore.hospital!.attributes.timezone)}
            </span>
          </div>
        )
      }
    }

    return (
      <div className='doserecommendation-title-first-last-dose'>
        <span className='doserecommendation-bold-title'>No doses found</span>
      </div>
    )
  }

  const isCurrentSimulationTabError = () => {
    return dosingRecommendationStore.error[currentModelView] ? true : false
  }

  if (size.width > 1200) {
    return (
      <>
        <DrugSwitchModal
          patientId={patient.id}
          course={course}
          show={showDrugSwitchModal}
          setShow={setShowDrugSwitchModal}
          drugModelId={courseStore.course!.attributes.drugModel.id}
        />

        <Header patientId={patient.id} isOnline={false} showEditButton />
        <div className='dose-components-wrapper'>
          {!courseStore.course?.attributes.courseArchived &&
            !!courseStore.course?.attributes.isReadOnly &&
            courseStore.course!.attributes.hasApplicableDrugModels && (
            <div className='doserecommendation-info-bubble'>
              <InfoBubble
                type='info'
                bubbleTitle={
                  'Note: This course is read only as the drug model is no longer available. You can edit the drug model and switch to another applicable model in order to make changes.'
                }
              />
            </div>
          )}
          <div className='doserecommendation-title-wrapper d-flex align-items-start mt-4'>
            <BackArrowButton
              data-testid='back-btn'
              onClick={() => handleBackButton(`/patients/${patientId}`, navigate, location)}
            />

            <div data-testid='doserecommendation-title' className='doserecommendation-title mb-4 ml-4'>
              <div data-testid='drugname' className='d-flex align-items-center'>
                {getDrugNameFromDrugModel(courseStore.course!.attributes.drugModel.name!)}
                {courseStore.course!.attributes.hasApplicableDrugModels &&
                  !courseStore.course?.attributes.courseArchived ? (
                    <div data-testid='drugmodel' className='doserecommendation-title-listbutton'>
                      <ActionButton
                        actionType='edit'
                        size='lg'
                        alignIconRight={true}
                        onClick={() => setShowDrugSwitchModal(true)}
                        customLabel={getModelNameFromDrugModel(courseStore.course!.attributes.drugModel.name!)}
                        disabled={switchDrugModelDisabled}
                      />
                    </div>
                  ) : (
                    <div className='doserecommendation-title-drug-model-archived'>
                    - {getModelNameFromDrugModel(courseStore.course!.attributes.drugModel.name!)}
                    </div>
                  )}
                {historicalSimulationStore.historicalSimulationData?.attributes.hasBetterFittingModel && (
                  <div className='better-applicable-model-notification'>
                    <div className='ml-2 mr-2'>
                      <Icons.Star width={20} height={20} />
                    </div>
                    We've found another model that may be better for this patient.
                  </div>
                )}
                <div className='doserecommendation-page-tags'>
                  {!courseStore.course?.attributes.drugModel.isValidated ? (
                    <div className='doserecommendation-tag'>
                      <Tooltip
                        id='dosing-recommendation-research-info-tip'
                        place='top'
                        className='course-variables-tooltip-width'
                      >
                        For research purposes only.
                        <br />
                        Not for clinical use.
                      </Tooltip>
                      <div>
                        <Tag
                          size='large'
                          text='Research'
                          color={red100}
                          icon={<Icons.Laptop size='l' />}
                          iconRight={
                            <div data-tooltip-id='dosing-recommendation-research-info-tip'>
                              <Icons.Info />
                            </div>
                          }
                        />
                      </div>
                    </div>
                  ) : null}
                  {courseStore.course?.attributes.drugModel.isNew ? (
                    <div className='doserecommendation-tag'>
                      <Tag size='large' icon={<Icons.TagIcon size='l' />} text='New model' />
                    </div>
                  ) : null}
                  {courseStore.course?.attributes.courseArchived ? (
                    <div className='doserecommendation-tag'>
                      <Tag size='large' icon={<Icons.Archive />} text='Archived (read-only)' color={INPUT_GREY} />
                    </div>
                  ) : null}
                </div>
              </div>
              {courseDoseWindow()}
            </div>
          </div>

          <Container fluid={true}>
            <Row className='row-position-static'>
              <Col key='main-col' width={8}>
                <DosingProfilePanel
                  hospitalTimezone={hospitalStore.hospital!.attributes.timezone}
                  setShowAddAdminModal={setShowAddAdminModal}
                  selectedSimulationPanelTab={currentModelView}
                  isIndividualized={isIndividualized}
                  customTypeTab={customTypeTab}
                  currentSimulation={currentSimulation}
                  isCurrentSimulationTabError={isCurrentSimulationTabError()}
                />

                <RecordedCourseDataPanel
                  hospitalTimezone={hospitalStore.hospital!.attributes.timezone}
                  patientId={patient.id}
                  course={course}
                  isIndividualized={isIndividualized}
                  selectedSimulationPanelTab={currentModelView}
                  showAddAdminModal={showAddAdminModal}
                  limits={course.attributes.limits}
                  setShowAddAdminModal={setShowAddAdminModal}
                />

                {courseStore.isDocetaxelCourse() ? (
                  <DocetaxelSimulationPanel
                    hospitalTimezone={hospitalStore.hospital!.attributes.timezone}
                    patientId={patient.id}
                    courseId={course.id}
                    drugModelId={course.attributes.drugModel.id}
                    setCurrentSimulation={setCurrentSimulation}
                  />
                ) : (
                  <SimulationPanel
                    hospitalId={hospitalStore.hospital!.id}
                    hospitalTimezone={hospitalStore.hospital!.attributes.timezone}
                    patientId={patient.id}
                    courseId={course.id}
                    drugModelId={course.attributes.drugModel.id}
                    limits={course.attributes.limits}
                    currentModel={currentModelView}
                    isIndividualized={isIndividualized}
                    currentSimulation={currentSimulation}
                    setCurrentModel={setCurrentModelView}
                    setIndividualized={setIndividualized}
                    customTypeTab={customTypeTab}
                    setCustomTypeTab={setCustomTypeTab}
                    setCurrentSimulation={setCurrentSimulation}
                  />
                )}
              </Col>
              <Col key='course-details-col' width={4}>
                <div data-testid='course-features-panel' className='mb-4'>
                  <CourseFeaturesPanel
                    patientId={patientId}
                    courseId={course.id}
                    drugModelId={course.attributes.drugModel.id}
                  />
                </div>

                <div data-testid='other-indicator-panel' className='mb-4'>
                  <OtherIndicatorsPanel selectedSimulationPanelTab={currentModelView} />
                </div>

                <div data-testid='pk-parameter-panel' className='mb-4'>
                  <PKParametersPanel selectedSimulationPanelTab={currentModelView} />
                </div>

                <div data-testid='activity-log-panel' className='mb-4'>
                  <ActivityLogs patientId={patientId} course={course} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    )
  }

  return (
    <>
      <DrugSwitchModal
        patientId={patient.id}
        course={course}
        show={showDrugSwitchModal}
        setShow={setShowDrugSwitchModal}
        drugModelId={courseStore.course!.attributes.drugModel.id}
      />

      <Header
        patientId={patient.id}
        isOnline={false}
        showEditButton
      />
      <div className='dose-components-wrapper' style={{ maxWidth: '800px' }}>
        {!courseStore.course?.attributes.courseArchived &&
          !!courseStore.course?.attributes.isReadOnly &&
          courseStore.course!.attributes.hasApplicableDrugModels && (
          <div className='doserecommendation-info-bubble'>
            <InfoBubble
              type='info'
              bubbleTitle={
                'Note: This course is read only as the drug model is no longer available. You can edit the drug model and switch to another applicable model in order to make changes.'
              }
            />
          </div>
        )}
        <div className='d-flex align-items-start'>
          <BackArrowButton
            data-testid='back-btn'
            onClick={() => handleBackButton(`/patients/${patientId}`, navigate, location)}
          />

          <div data-testid='doserecommendation-title' className='doserecommendation-title mb-4 ml-4'>
            <div data-testid='drugname' className='d-flex align-items-center'>
              {getDrugNameFromDrugModel(courseStore.course!.attributes.drugModel.name!)}
              {courseStore.course!.attributes.hasApplicableDrugModels ? (
                <div data-testid='drugmodel' className='doserecommendation-title-listbutton'>
                  <ActionButton
                    actionType='edit'
                    size='lg'
                    alignIconRight={true}
                    onClick={() => setShowDrugSwitchModal(true)}
                    customLabel={getModelNameFromDrugModel(courseStore.course!.attributes.drugModel.name!)}
                    disabled={switchDrugModelDisabled}
                  />
                </div>
              ) : (
                ` - ${getModelNameFromDrugModel(courseStore.course!.attributes.drugModel.name!)}`
              )}
            </div>
            {courseDoseWindow()}
          </div>
        </div>

        <Container fluid={true}>
          <Row className='row-position-static'>
            <Col key='main-col' width={12}>
              <div data-testid='course-features-panel' className='mb-4'>
                <CourseFeaturesPanel
                  patientId={patientId}
                  courseId={course.id}
                  drugModelId={course.attributes.drugModel.id}
                />
              </div>

              <DosingProfilePanel
                hospitalTimezone={hospitalStore.hospital!.attributes.timezone}
                setShowAddAdminModal={setShowAddAdminModal}
                selectedSimulationPanelTab={currentModelView}
                isIndividualized={isIndividualized}
                customTypeTab={customTypeTab}
                currentSimulation={currentSimulation}
                isCurrentSimulationTabError={isCurrentSimulationTabError()}
              />

              <RecordedCourseDataPanel
                hospitalTimezone={hospitalStore.hospital!.attributes.timezone}
                patientId={patient.id}
                course={course}
                isIndividualized={isIndividualized}
                selectedSimulationPanelTab={currentModelView}
                showAddAdminModal={showAddAdminModal}
                limits={course.attributes.limits}
                setShowAddAdminModal={setShowAddAdminModal}
              />

              {courseStore.isDocetaxelCourse()
                ? <DocetaxelSimulationPanel
                  hospitalTimezone={hospitalStore.hospital!.attributes.timezone}
                  patientId={patient.id}
                  courseId={course.id}
                  drugModelId={course.attributes.drugModel.id}
                  setCurrentSimulation={setCurrentSimulation}
                />
                : <SimulationPanel
                  hospitalId={hospitalStore.hospital!.id}
                  hospitalTimezone={hospitalStore.hospital!.attributes.timezone}
                  patientId={patient.id}
                  courseId={course.id}
                  drugModelId={course.attributes.drugModel.id}
                  limits={course.attributes.limits}
                  currentModel={currentModelView}
                  isIndividualized={isIndividualized}
                  currentSimulation={currentSimulation}
                  setCurrentModel={setCurrentModelView}
                  setIndividualized={setIndividualized}
                  customTypeTab={customTypeTab}
                  setCustomTypeTab={setCustomTypeTab}
                  setCurrentSimulation={setCurrentSimulation}
                />
              }
              <div className='d-flex w-100'>
                <div className='tablet-view-side-panels'>
                  <div data-testid='other-indicator-panel' className='other-indicators'>
                    <OtherIndicatorsPanel selectedSimulationPanelTab={currentModelView} />
                  </div>

                  <div data-testid='pk-parameter-panel' className='mb-4'>
                    <PKParametersPanel selectedSimulationPanelTab={currentModelView} />
                  </div>
                </div>

                <div data-testid='activity-log-panel' className='tablet-view-activity-log'>
                  <ActivityLogs patientId={patientId} course={course} />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
})

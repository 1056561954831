import { InfoModal, Modal, Button, DatePickerInput, TimeInput, ITimeState, TextInput, ValidationMessage } from '@doseme/cohesive-ui'
import { observer } from 'mobx-react-lite'
import moment from 'moment-timezone'
import { useEffect } from 'react'

import { rawDateOnly, rawTimeOnly } from '../../../../../../../../constants/timeFormat'
import { useAdministrationsStore, useCourseStore, useObservationsStore } from '../../../../../../../../hooks/useStore'
import { showErrorToast, showSuccessToast } from '../../../../../../../../shared/toast'
import { Administration } from '../../../../../../../../store/administrations/Administration'
import { Course } from '../../../../../../../../store/course/Course'
import { IModalProps } from '../../../../../../types'
import { useFormValidation } from '../../../../../../../../hooks/useFormValidation'
import { IFormField } from '../../../../../../../../types/validation'
import {
  isLessThanFourDecimalPlaces,
  isRequired,
  isStringValidNumber,
  isStringWithinNumericLimits,
  isTimeStateDSTValid,
  isValidTimeState
} from '../../../../../../../../utils/validation/rules'
import { dateToISODateString, stringToFloat, timeStateToTimeString } from '../../../../../../../../utils/validation/formatters'
import { getEditAdminLimits } from './utils'

interface IProps extends IModalProps {
  admin: Administration
  patientId: string
  course: Course
  setAdmin: (admin: Administration | null) => void
  hospitalTimezone: string
}

const EditDoseModal: React.FC<IProps> = observer((props) => {
  const administrationsStore = useAdministrationsStore()
  const observationsStore = useObservationsStore()
  const courseStore = useCourseStore()

  const amountUnit = props.admin.attributes.amount.unit!
  const infusionLengthUnitName = props.course.attributes.limits.infusionLength.default.unit?.name

  const courseLimits = props.course.attributes.limits

  const initialAmountValue = props.admin.attributes.amount.value.toString()
  const initialInfusionLength = props.admin.attributes.infusionLength?.value.toString() || null

  const initialDateTimeAdministered = moment.tz(props.admin.attributes.administeredAt.value, props.hospitalTimezone)
  const initialDateAdministered = new Date(initialDateTimeAdministered.format(rawDateOnly) + 'T00:00:00')

  const initialTimeAdministered = initialDateTimeAdministered.format(rawTimeOnly)
  const initialTimeStateAdministered: ITimeState = {
    hh: initialTimeAdministered.split(':')[0],
    mm: initialTimeAdministered.split(':')[1]
  }
  const textInputLimits = getEditAdminLimits(
    props.admin.attributes.administrationType.id,
    props.course.attributes.drugModel.id,
    courseLimits,
    courseStore.course?.attributes.administrationTypes
  )

  const formFields: Record<string, IFormField> = {
    dateAdministered: {
      // Input - Date object
      // Value - ISO Date string yyyy-MM-dd
      initialInput: initialDateAdministered,
      rules: [
        isRequired // remaining validation implicitly handled by DateTimePicker logic for now
      ],
      formatter: dateToISODateString
    },
    timeAdministered: {
      // Input - ITimeState
      // Value - Time string HH:mm
      initialInput: initialTimeStateAdministered,
      initialConstraints: {
        date: initialDateAdministered,
        tz: props.hospitalTimezone
      },
      rules: [isRequired, isValidTimeState, isTimeStateDSTValid],
      formatter: timeStateToTimeString
    },
    amount: {
      // Input - string
      // Value - number
      initialInput: initialAmountValue,
      initialConstraints: textInputLimits.amountConstraints,
      rules: [isRequired, isStringValidNumber, isStringWithinNumericLimits, isLessThanFourDecimalPlaces],
      formatter: stringToFloat
    },
    infusionLength: {
      // Input - string
      // Value - number
      initialInput: initialInfusionLength,
      initialConstraints: textInputLimits.infusionLengthConstraints,
      rules: [isStringValidNumber, isStringWithinNumericLimits, isLessThanFourDecimalPlaces],
      formatter: stringToFloat
    }
  }

  const form = useFormValidation(formFields)

  // As this is an edit form, show all validation statuses for fields on initial render
  useEffect(() => { form.updateFieldsDisplay(Object.keys(formFields)) }, [])

  const handleSubmit = async () => {
    const result = await administrationsStore.editAdministration(
      props.admin.id,
      props.patientId,
      props.admin.attributes.courseId,
      props.admin.attributes.molecule,
      {
        value: form.values['amount'],
        unit: props.admin.attributes.amount.unit
      },
      form.values['infusionLength'] ? parseFloat(form.values['infusionLength']) : null,
      moment
        .tz(form.values['dateAdministered'] + 'T' + form.values['timeAdministered'], props.hospitalTimezone)
        .utc()
        .toISOString(),
      props.admin.attributes.excludeFromCalculations
    )

    if (!result || administrationsStore.loadState === 'updateError') {
      if (administrationsStore.error) {
        showErrorToast(administrationsStore.error || 'Failed to update dose')

        return
      }
    }

    // ensures that duplicates all update simultaneously.
    await administrationsStore.fetchAdminsForPatientCourse(props.patientId, props.course.id)

    showSuccessToast('Dose updated')
    props.setShow(false)
    props.setAdmin(null)

    // Reload observations after admins have been updated
    await observationsStore.fetchObservationsForPatientCourse(props.patientId, props.course.id)
  }

  const handleClose = () => {
    props.setShow(false)
    props.setAdmin(null)
    form.reset()
  }

  const formContent = (): JSX.Element => {
    return (
      <div className='position-relative w-100'>
        <div className='d-flex w-100'>
          <div>
            <DatePickerInput
              label='Date & time (00:00-23:59):'
              fieldState={form.getValidState('dateAdministered')}
              hasValidation={false}
              validationText={form.getValidationMsg('dateAdministered')}
              value={form.inputs['dateAdministered']}
              onChange={(value) =>
                form.validateFields(
                  [
                    {
                      field: 'dateAdministered',
                      input: value
                    },
                    {
                      field: 'timeAdministered',
                      input: form.inputs['timeAdministered'],
                      constraints: {
                        date: value,
                        tz: props.hospitalTimezone
                      }
                    }
                  ],
                  'updateFieldsDisplay'
                )
              }
              testId='date-input'
            />
          </div>
          <div className='time-modal-input ml-2'>
            <TimeInput
              label={<span>&#8205;</span>}
              fieldState={form.getValidState('timeAdministered')}
              hasValidation={false}
              validationText={form.getValidationMsg('timeAdministered')}
              testIdHh='time-input-hh'
              testIdMm='time-input-mm'
              value={form.inputs['timeAdministered']}
              onChange={(value: ITimeState) => {
                form.validateFields([
                  {
                    field: 'timeAdministered',
                    input: value,
                    constraints: {
                      date: form.inputs['dateAdministered'],
                      tz: props.hospitalTimezone
                    }
                  }
                ])
              }}
              onBlur={() => form.updateFieldsDisplay(['timeAdministered'])}
            />
          </div>
        </div>
        <ValidationMessage
          labelState='error'
          message={form.getValidationMsg('dateAdministered') || form.getValidationMsg('timeAdministered')}
        />

        <div className='mt-4'>
          <TextInput
            label='Dose amount:'
            fieldState={form.getValidState('amount')}
            validationText={form.getValidationMsg('amount')}
            value={form.inputs['amount']}
            name='amount-input-edit'
            onChange={(value) =>
              form.validateFields([
                {
                  field: 'amount',
                  input: value,
                  constraints: formFields.amount.initialConstraints
                }
              ])
            }
            onBlur={() => form.updateFieldsDisplay(['amount'])}
            units={amountUnit.name}
          />
        </div>
        {initialInfusionLength && (
          <div className='mt-4'>
            <TextInput
              label='Infusion length:'
              fieldState={form.getValidState('infusionLength')}
              validationText={form.getValidationMsg('infusionLength')}
              value={form.inputs['infusionLength']}
              name='infusion-length-input'
              onChange={(value) =>
                form.validateFields([
                  {
                    field: 'infusionLength',
                    input: value,
                    constraints: formFields.infusionLength.initialConstraints
                  }
                ])
              }
              onBlur={() => form.updateFieldsDisplay(['infusionLength'])}
              units={infusionLengthUnitName}
            />
          </div>
        )}
      </div>
    )
  }

  return (
    <Modal
      show={props.show}
      onHide={handleClose}
    >
      <InfoModal
        size='s'
        linkComponent={
          <Button
            data-testid='save-btn'
            disabled={!form.valid || administrationsStore.loadState === 'updating'}
            loading={administrationsStore.loadState === 'updating'}
            onClick={handleSubmit}
            variant='primary'
          >
            Save
          </Button>
        }
        title='Edit dose'
        message={formContent()}
        onDismiss={handleClose}
      />
    </Modal>
  )
})

export { EditDoseModal }

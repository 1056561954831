import { Button, InfoModal, Modal, ActionButton, InfoBubble } from '@doseme/cohesive-ui'
import { observer } from 'mobx-react-lite'
import { useState, useEffect, useMemo } from 'react'
import moment from 'moment'
import pluralize from 'pluralize'
import classnames from 'classnames'

import {
  useAdministrationsStore,
  useObservationsStore,
  useHistoricalSimulationStore
} from '../../../../../../../../hooks/useStore'
import { Course } from '../../../../../../../../store/course/Course'
import { IModalProps } from '../../../../../../types'
import { AddAdminObsPanel } from './AddAdminObsPanel'
import { AddDoseForm } from './AddDoseForm'
import { AddObservationForm } from './AddObservationForm'
import { showErrorToast, showSuccessToast } from '../../../../../../../../shared/toast'
import { IPostAdministration } from '../../../../../../../../store/administrations/types'
import { IObservationType, IPostObservation } from '../../../../../../../../store/observations/types'
import { sortObservationsByDateAsc } from '../../../../../../../../utils/sorting'
import { getLastSeCrObservation } from '../SimulationPanel/utils'
import { getSortedFormKeys, getLatestValidForm, getInitialValues, getAssumedDosingInterval, getLatestAdministrationOfType } from './utils'
import { IFormState } from '../../../../../../../../types/validation'
import { ICourseAdministrationType } from '../../../../../../../../store/course/types'
import { getCurrentAdministrationUnits } from './AddDoseForm/utils'

import './index.scss'

interface IProps extends IModalProps {
  patientId: string
  course: Course
  hospitalTimezone: string
  type: 'dose' | 'observation'
}

export const AddDoseOrObservationModal: React.FC<IProps> = observer((props) => {
  const scrollStart = 5
  const [valid, setValid] = useState<boolean>(false)
  const [forms, setForms] = useState<{ [id: number]: IFormState | undefined }>({ 0: undefined })

  const [updateScroll, setUpdateScroll] = useState<boolean>(false)

  const administrationsStore = useAdministrationsStore()
  const observationsStore = useObservationsStore()
  const historicalSimulationStore = useHistoricalSimulationStore()

  // We use an ES6 Map here to preserve the order of observation types returned by the endpoint
  const obsTypesForCourse: Array<[string, IObservationType]> = props.course.attributes.observationTypes.map((o) => [
    o.id,
    o
  ])
  const adminTypesForCourse: Array<[string, ICourseAdministrationType]> = props.course.attributes.administrationTypes.map(
    (o) => [o.id, o]
  )

  const observationTypes: Map<string, IObservationType> = new Map(obsTypesForCourse)
  const administrationTypes: Map<string, ICourseAdministrationType> = new Map(adminTypesForCourse)
  const molecule = props.course.attributes.drugModel.molecule

  const lastIncludedSeCrObservation = useMemo(() => {
    const seCrObservations = [...observationsStore.observations.values()].filter(
      (x) => x.attributes.observationType.name === 'Serum Creatinine' && !x.attributes.excludeFromCalculations
    )

    return getLastSeCrObservation(sortObservationsByDateAsc(seCrObservations))
  }, [observationsStore.loadState])

  // reset forms when:
  //  - changing drug models
  //  - changing from doses to admins
  useEffect(() => {
    setForms({ 0: undefined })
  }, [props.course.attributes.drugModel.id, props.type, props.show])

  useEffect(() => {
    if (Object.keys(forms).length >= scrollStart && updateScroll) {
      const formDiv = document.getElementsByClassName('message-box-limit-height')[0]
      formDiv.scrollTop = formDiv.scrollHeight
    }
    setUpdateScroll(false)
  }, [updateScroll])

  const checkValidation = () => {
    const validations = Object.keys(forms).map((id, index) => {
      return forms[parseInt(id)]?.valid || false
    })

    setValid(!validations.includes(false))
  }

  const handleSubmitDoses = async () => {
    const administrations: IPostAdministration[] = getSortedFormKeys(forms, props.type).reduce(
      (result: IPostAdministration[], id: string) => {
        const values = forms[parseInt(id)]?.values

        if (values) {
          const postAdmin: IPostAdministration = {
            molecule: values.administrationType.molecule || molecule,
            amount: {
              value: values['amount'],
              unit: getCurrentAdministrationUnits(
                administrationTypes,
                values.administrationType.id
              )
            },
            // The below is to determine whether the course drug model is Docetaxel and the admin selected isn't
            infusionLength: props.course.attributes.drugModel.id !== '54' || values.administrationType.id === '54'
              ? parseFloat(values['infusionLength'])
              : null,
            administeredAt: moment
              .tz(values['dateAdministered'] + 'T' + values['timeAdministered'], props.hospitalTimezone)
              .utc()
              .toISOString(),
            excludeFromCalculations: false
          }
          result.push(postAdmin)
        }

        return result
      },
      []
    )

    const result = await administrationsStore.createAdministrations(props.patientId, props.course.id, administrations)
    if (!result || administrationsStore.loadState === 'updateError') {
      showErrorToast(administrationsStore.error || `Failed to record ${pluralize('dose', Object.keys(forms).length)}`)

      return
    }

    await administrationsStore.fetchAdminsForPatientCourse(props.patientId, props.course.id)
    showSuccessToast(`${pluralize('Dose', Object.keys(forms).length)} saved`)
    handleClose()

    // Reload observations after admins have been updated
    await observationsStore.fetchObservationsForPatientCourse(props.patientId, props.course.id)
  }

  const handleSubmitObservations = async () => {
    const observations: IPostObservation[] = getSortedFormKeys(forms, props.type).reduce(
      (result: IPostObservation[], id) => {
        const values = forms[parseInt(id)]?.values

        if (values) {
          //must remove the "units" from payload if INR
          let amount = {
            value: values['amount'],
            unit: observationTypes.get(values['observationType'].id)!.unit!
          }

          let observationType = values['observationType']

          if (amount['unit'] && observationType['unit'] && values.observationType.name === 'INR') {
            amount['unit']['name'] = 'N/A'
            observationType['unit']['name'] = 'N/A'
          }

          const postObs: IPostObservation = {
            observationType: observationType,
            amount: amount,
            observedAt: moment
              .tz(values['dateObserved'] + 'T' + values['timeObserved'], props.hospitalTimezone)
              .utc()
              .toISOString(),
            excludeFromCalculations: false
          }
          result.push(postObs)
        }

        return result
      },
      []
    )

    const result = await observationsStore.createObservations(props.patientId, props.course.id, observations)

    if (!result || observationsStore.loadState === 'updateError') {
      showErrorToast(
        observationsStore.error || `Failed to record ${pluralize('observation', Object.keys(forms).length)}`
      )

      return
    }

    await observationsStore.fetchObservationsForPatientCourse(props.patientId, props.course.id)
    showSuccessToast(`${pluralize('Observation', Object.keys(forms).length)} saved`)
    handleClose()
  }

  const handleClose = () => {
    setForms({ 0: undefined })
    props.setShow(false)
  }

  const removeAdminOrObs = (id: number) => {
    const newForms = Object.assign({}, forms)
    delete newForms[id]
    setForms(newForms)
  }

  const addAdminOrObs = () => {
    const lastId = Object.keys(forms)[Object.keys(forms).length - 1]
    const newId = parseInt(lastId) + 1
    const newForms = Object.assign({}, forms)
    newForms[newId] = undefined
    setForms(newForms)
    setUpdateScroll(true)
  }

  //Updates values but doesn't trigger re-render
  const receiveFormCallback = (id: number, form: any) => {
    const newForms = forms
    newForms[id] = form
    setForms(newForms)
    checkValidation()
  }

  const formContent = (): JSX.Element => {
    const latestFormKey = Object.keys(forms).pop()
    const sortedKeys = getSortedFormKeys(forms, props.type, latestFormKey)
    let lastForm: IFormState | undefined
    let initialValues: Record<string, any> | undefined

    if (props.type === 'dose') {
      lastForm = getLatestValidForm(sortedKeys, forms)

      const latestIncludedAdministrationOfType = getLatestAdministrationOfType(
        [...administrationsStore.administrations.values()],
        lastForm?.values['administrationType']?.id || [...administrationTypes][0][0]
      )

      const assumedDosingInterval = getAssumedDosingInterval(
        sortedKeys,
        forms,
        lastForm?.values['administrationType']?.id || [...administrationTypes][0][0],
        latestIncludedAdministrationOfType,
        historicalSimulationStore.historicalSimulationData?.attributes.assumedDosingInterval
      )

      initialValues = getInitialValues(
        lastForm,
        props.course,
        props.hospitalTimezone,
        administrationsStore,
        observationsStore,
        assumedDosingInterval,
        latestIncludedAdministrationOfType,
        lastIncludedSeCrObservation
      )
    }

    const listItems = sortedKeys.map((id, index) => {
      return (
        <AddAdminObsPanel
          key={id}
          index={index}
          arraySize={sortedKeys.length}
          onDismiss={() => removeAdminOrObs(parseInt(id))}
        >
          {props.type === 'dose' ? (
            <AddDoseForm
              id={parseInt(id)}
              inputs={initialValues}
              patientId={props.patientId}
              course={props.course}
              hospitalTimezone={props.hospitalTimezone}
              administrationTypesMap={administrationTypes}
              returnForm={receiveFormCallback}
            />
          ) : (
            <AddObservationForm
              id={parseInt(id)}
              patientId={props.patientId}
              course={props.course}
              hospitalTimezone={props.hospitalTimezone}
              observationTypesMap={observationTypes}
              returnForm={receiveFormCallback}
            />
          )}
        </AddAdminObsPanel>
      )
    })

    return <div style={{ width: '100%' }}>{listItems}</div>
  }

  const buttons = () => {
    return (
      <div className='admin-obs-modal-buttons'>
        <div className='admin-obs-modal-add-new-button'>
          <ActionButton
            data-testid='add-admin-obs-button'
            actionType='add'
            onClick={addAdminOrObs}
            customLabel={props.type === 'dose' ? 'Add a subsequent dose' : 'Add another observation'}
          />
        </div>
        <Button
          className='admin-obs-modal-save-button'
          disabled={
            !valid ||
            (props.type === 'dose'
              ? administrationsStore.loadState === 'updating'
              : observationsStore.loadState === 'updating')
          }
          loading={administrationsStore.loadState === 'updating'}
          onClick={props.type === 'dose' ? handleSubmitDoses : handleSubmitObservations}
          variant='primary'
        >
          {'Save ' + Object.keys(forms).length + ' ' + pluralize(props.type, Object.keys(forms).length)}
        </Button>
      </div>
    )
  }

  return (
    <div
      className={classnames('add-doses-observations-wrapper')}
    >
      <Modal show={props.show} onHide={handleClose}>
        <InfoModal
          limitHeightToWindow={Object.keys(forms).length >= scrollStart}
          size='variable'
          linkComponent={buttons()}
          title={props.type === 'dose' ? 'Add Doses' : 'Add Observations'}
          message={formContent()}
          onDismiss={handleClose}
          subtitle={
            props.type === 'dose' ? (
              <div className='add-doses-observations-subtitle'>
                <InfoBubble
                  bubbleTitle={
                    <div className='add-doses-assumed-interval-info'>
                      The date & time is pre-filled based on the interval from prior doses, or from the <br/>
                      default interval setting.
                    </div>
                  }
                />
              </div>
            ) : undefined
          }
        />
      </Modal>
    </div>
  )
})

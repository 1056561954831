import { useState, useEffect, useLayoutEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { ObservableMap } from 'mobx'
import {
  ActionButton,
  ControlButton,
  BasicList,
  ConfirmModal,
  FloatingTabBar,
  IColumnElement,
  Icons,
  IHeaderItem,
  IRowElement,
  ITab,
  ITooltipMenuData,
  ListButton,
  Modal,
  TooltipMenu,
  INVALID_RED,
  VALID_GREEN,
  ISelectedRows
} from '@doseme/cohesive-ui'
import { observer } from 'mobx-react-lite'
import classnames from 'classnames'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment-timezone'
import { Tooltip } from 'react-tooltip'

import { handleSeparatorThreshold } from './utils'
import { sortAlpha } from '../../../../../../../../utils/smartListUtils'
import {
  useCourseStore,
  useAdministrationsStore,
  useObservationsStore,
  useDosingRecommendationStore,
  useHistoricalSimulationStore
} from '../../../../../../../../hooks/useStore'
import { formatToDisplayDate } from '../../../../../../../../utils/dates'
import { Administration } from '../../../../../../../../store/administrations/Administration'
import { Observation } from '../../../../../../../../store/observations/Observation'
import { EditDoseModal } from '../EditDoseModal'
import { Course } from '../../../../../../../../store/course/Course'
import { showErrorToast, showSuccessToast } from '../../../../../../../../shared/toast'
import { TPredictedFilter, TRecordFilter } from './types'
import { AddDoseOrObservationModal } from '../AddDoseOrObservationModal'
import {
  IComputedOutcomesPerDose,
  IHDData,
  IPerDoseOutcome,
  TModelType
} from '../../../../../../../../store/dosingRecommendation/types'
import { getModelResults } from '../../../../../../../../store/dosingRecommendation/utils'
import { EditObservationModal } from '../EditObservationModal'
import { IObservationType } from '../../../../../../../../store/observations/types'
import { IAdministration } from '../../../../../../../../store/administrations/types'
import { ICourseLimits } from '../../../../../../../../store/course/types'
import { sortAdministrationsByDateAsc } from '../../../../../../../../utils/sorting'
import { TTargetTypes } from '../SimulationPanel/types'

import './index.scss'

interface IProps {
  patientId: string
  course: Course
  hospitalTimezone: string
  isIndividualized: boolean
  selectedSimulationPanelTab: TModelType
  showAddAdminModal: boolean
  limits: ICourseLimits
  setShowAddAdminModal: (show: boolean) => void
}

const recordTabs: ITab[] = [
  { id: 'all', displayName: 'View All' },
  { id: 'administrations', displayName: 'Doses' },
  { id: 'observations', displayName: 'Observations' }
]

const isWarfarinMolecule = (moleculeName: string): boolean => {
  return moleculeName === 'Warfarin'
}

const getCols = (
  predictedFilter: TPredictedFilter,
  moleculeName: string,
  activePredictedTab: TPredictedFilter,
  isDocetaxelCourse: boolean
): IHeaderItem[] => {
  let spacingStyle = ''

  if (activePredictedTab === 'peak/trough') {
    spacingStyle = 'pt-'
  }

  if (isWarfarinMolecule(moleculeName)) {
    spacingStyle = 'inr-'
  }

  if (activePredictedTab === 'cumulative_auc') {
    spacingStyle = 'cml-'
  }

  const recordColumns: IHeaderItem[] = [
    {
      name: 'Type',
      width: 1,
      className: `${spacingStyle}type-header-col-width-adjust`
    },
    {
      name: 'Time',
      width: 3,
      className: `${spacingStyle}time-header-col-width-adjust`
    },
    {
      name: 'Amount',
      width: 2,
      className: `${spacingStyle}amount-header-col-width-adjust`
    },
    {
      name: 'Inf. Length',
      width: 2,
      className: `${spacingStyle}infusion-header-col-width-adjust`
    },
    {
      name: '',
      width: 1
    }
  ]
  let predictedColumns: IHeaderItem[]

  if (isWarfarinMolecule(moleculeName)) {
    predictedColumns = [
      {
        name: 'Min. INR',
        width: 2,
        className: `${spacingStyle}predicted-left-header-col-width-adjust`
      },
      {
        name: 'Max. INR',
        width: 2,
        className: `${spacingStyle}predicted-right-header-col-width-adjust`
      }
    ]

    return recordColumns.concat(predictedColumns)
  }

  if (activePredictedTab === 'cumulative_auc') {
    predictedColumns = [
      {
        name: 'Cml. AUC',
        width: 2,
        className: `${spacingStyle}predicted-left-header-col-width-adjust`
      },
      {
        name: '% of target',
        width: 2,
        className: `${spacingStyle}predicted-right-header-col-width-adjust`
      }
    ]

    return recordColumns.concat(predictedColumns)
  }

  if (isDocetaxelCourse) {
    predictedColumns = [
      {
        name: '—',
        width: 1,
        className: `${spacingStyle}predicted-left-header-col-width-adjust-docetaxel`
      },
      {
        name: '—',
        width: 2,
        className: `${spacingStyle}predicted-right-header-col-width-adjust`
      }
    ]

    return recordColumns.concat(predictedColumns)
  }

  if (predictedFilter === 'auc') {
    predictedColumns = [
      {
        name: 'AUCₜ',
        width: 1,
        className: `${spacingStyle}predicted-left-header-col-width-adjust`
      },
      {
        name: 'AUC24',
        width: 2,
        className: `${spacingStyle}predicted-right-header-col-width-adjust`
      }
    ]

    return recordColumns.concat(predictedColumns)
  }

  predictedColumns = [
    {
      name: 'Peak',
      width: 1,
      className: `${spacingStyle}predicted-left-header-col-width-adjust`
    },
    {
      name: 'Trough',
      width: 2,
      className: `${spacingStyle}predicted-right-header-col-width-adjust`
    }
  ]

  return recordColumns.concat(predictedColumns)
}

const getPredictedTabs = (
  moleculeName: string,
  defaultTargetName: TTargetTypes,
  isDocetaxelCourse: boolean
) => {
  if (isWarfarinMolecule(moleculeName)) {
    return [{ id: 'range', displayName: 'INR' }]
  }

  if (isDocetaxelCourse) {
    return []
  }

  if (defaultTargetName === 'cumulative_auc') {
    return [
      { id: 'auc', displayName: 'AUC' },
      { id: 'cumulative_auc', displayName: 'Cml. AUC' }
    ]
  }

  return [
    { id: 'auc', displayName: 'AUC' },
    { id: 'peak/trough', displayName: 'Peak/Trough' }
  ]
}

const sortColIndex = 1
const sortColAscending = true
let data: IRowElement[] = []

const RecordedCourseDataPanel: React.FC<IProps> = observer((props) => {
  // We use an ES6 Map here to preserve the order of observation types returned by the endpoint
  const obsTypesForCourse: Array<[string, IObservationType]> = props.course.attributes.observationTypes.map((o) => [
    o.id,
    o
  ])
  const observationTypes: Map<string, IObservationType> = new Map(obsTypesForCourse)
  const maxTypeStringLength = 9

  const tab =
    !!useLocation().hash && ['#administrations', '#observations'].includes(useLocation().hash)
      ? useLocation().hash.replace('#', '')
      : 'all'

  const courseStore = useCourseStore()
  const administrationsStore = useAdministrationsStore()
  const observationsStore = useObservationsStore()
  const historicalSimulationStore = useHistoricalSimulationStore()
  const dosingRecommendationStore = useDosingRecommendationStore()

  const [historicalOutcomes, setHistoricalOutcomes] = useState<IComputedOutcomesPerDose | null>(null)

  const [showAddObsModal, setShowAddObsModal] = useState(false)
  const [activeRecordTab, setActiveRecordTab] = useState<TRecordFilter>(
    tab as 'all' | 'administrations' | 'observations'
  )

  const initialActivePredictedTab = () => {
    if (isWarfarinMolecule(props.course.attributes.drugModel.molecule.name)) {
      return 'range'
    }

    if (defaultTarget === 'cumulative_auc') {
      return defaultTarget
    }

    return 'auc'
  }

  const defaultTarget = courseStore.course?.attributes.simulateNextDose.defaultCustomTarget.target
  const [activePredictedTab, setActivePredictedTab] = useState<TPredictedFilter>(initialActivePredictedTab())

  const [adminToEdit, setAdminToEdit] = useState<Administration | null>(null)
  const [adminToDelete, setAdminToDelete] = useState<Administration | null>(null)
  const [showEditAdminModal, setShowEditAdminModal] = useState(false)

  const [observationToEdit, setObservationToEdit] = useState<Observation | null>(null)
  const [obsToDelete, setObsToDelete] = useState<Observation | null>(null)
  const [showEditObservationModal, setShowEditObservationModal] = useState(false)

  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const [selectedRows, setSelectedRows] = useState<ISelectedRows>({})
  const [includeExclude, setIncludeExclude] = useState<'Include' | 'Exclude'>('Exclude')
  const [selectedRowCount, setSelectedRowCount] = useState<number>(0)

  const predictedDivRef = useRef<HTMLDivElement>(null)

  const [predictedDataSeparator, setPredictedDataSeparator] = useState<boolean>(false)

  const predictedTabs = getPredictedTabs(
    props.course.attributes.drugModel.molecule.name,
    props.course.attributes.simulateNextDose.defaultCustomTarget.target as TTargetTypes,
    courseStore.isDocetaxelCourse()
  )

  const cols = getCols(
    activePredictedTab,
    props.course.attributes.drugModel.molecule.name,
    activePredictedTab,
    courseStore.isDocetaxelCourse()
  )

  useEffect(() => {
    onSelectedRows({})

    const historicalOutcomesFromSimulationResults =
      dosingRecommendationStore.getHistoricalOutcomes(props.selectedSimulationPanelTab)

    if (dosingRecommendationStore.loadState === 'loaded' && historicalOutcomesFromSimulationResults) {
      setHistoricalOutcomes(historicalOutcomesFromSimulationResults)
    } else if (historicalSimulationStore.loadState === 'loaded') {
      setHistoricalOutcomes(historicalSimulationStore.getHistoricalOutcomes())
    }
  }, [historicalSimulationStore.loadState, dosingRecommendationStore.loadState, props.selectedSimulationPanelTab])

  useEffect(() => {
    if (courseStore.loadState === 'loaded') {
      setActivePredictedTab(initialActivePredictedTab())
      onSelectedRows({})
    }
  }, [administrationsStore.loadState])

  useEffect(() => {
    if (courseStore.loadState === 'loaded') {
      setActivePredictedTab(initialActivePredictedTab())

      if (administrationsStore.loadState === 'initial') {
        administrationsStore.fetchAdminsForPatientCourse(props.patientId, props.course.id)
      }

      if (observationsStore.loadState === 'initial') {
        observationsStore.fetchObservationsForPatientCourse(props.patientId, props.course.id)
      }

      onSelectedRows({})
    }
  }, [courseStore.loadState])

  useEffect(() => {
    dosingRecommendationStore.resetPredictedData()
    if (administrationsStore.loadState === 'loaded' && observationsStore.loadState === 'loaded') {
      onSelectedRows({})
    }
  }, [administrationsStore.loadState, observationsStore.loadState])

  const PREDICTED_SEPARATOR_HEIGHT_PX = 3

  // this could break if the HTML structure in the render method is changed
  const updateSeparator = () => {
    const listElementScrollPosition = predictedDivRef.current?.parentElement?.getBoundingClientRect().top
    const parentElementScrollPosition =
      predictedDivRef.current?.parentElement?.parentElement?.parentElement?.getBoundingClientRect().top

    handleSeparatorThreshold(
      setPredictedDataSeparator,
      listElementScrollPosition,
      parentElementScrollPosition,
      PREDICTED_SEPARATOR_HEIGHT_PX
    )
  }

  useEffect(() => {
    const element = document.getElementsByClassName('recorded-course-data-content')[0]
    if (dosingRecommendationStore.loadState === 'loaded' && element) {
      element.addEventListener('scroll', updateSeparator)
    }
  }, [dosingRecommendationStore.loadState, props.selectedSimulationPanelTab])

  // clear separator
  useEffect(() => {
    updateSeparator()
  }, [
    props.selectedSimulationPanelTab,
    administrationsStore.loadState,
    observationsStore.loadState,
    dosingRecommendationStore.loadState,
    activeRecordTab
  ])

  // Scroll to the bottom of the recorded course data table on initial load.
  useLayoutEffect(() => {
    if (
      administrationsStore.loadState === 'loaded' &&
      observationsStore.loadState === 'loaded' &&
      ['loaded', 'loadError', 'updateError'].includes(historicalSimulationStore.loadState)
    ) {
      const element = document.getElementsByClassName('co-basic-list')[0].children[1]
      element.scrollTop = element.scrollHeight
    }
  }, [
    administrationsStore.loadState,
    observationsStore.loadState,
    historicalSimulationStore.loadState,
    props.selectedSimulationPanelTab,
    activeRecordTab
  ])

  // Scroll logic for predicted simulations.
  useEffect(() => {
    if (['loaded', 'loadError', 'updateError'].includes(dosingRecommendationStore.loadState)) {
      const predictedDataSeparatorElement = document.getElementsByClassName('predicted-data-separator')[0] as HTMLElement

      if (predictedDataSeparatorElement) {
        const scrollableContainer = document.querySelector('.recorded-course-data-content') as HTMLElement

        if (scrollableContainer) {
          const containerRect = scrollableContainer.getBoundingClientRect()
          const lastHistoricalRecordElement = predictedDataSeparatorElement.previousElementSibling as HTMLElement

          if (lastHistoricalRecordElement) {
            // Scroll the recorded course data table to the position of the lastHistoricalRecordElement
            const elementRect = lastHistoricalRecordElement.getBoundingClientRect()
            scrollableContainer.scrollTop = elementRect.top - containerRect.top + scrollableContainer.scrollTop
          } else {
            // Otherwise, scroll the recorded course data table to the predictedDataSeparatorElement
            const elementRect = predictedDataSeparatorElement.getBoundingClientRect()
            scrollableContainer.scrollTop = elementRect.top - containerRect.top + scrollableContainer.scrollTop
          }
        }
      }
    }
  }, [
    dosingRecommendationStore.loadState,
    props.selectedSimulationPanelTab,
    activeRecordTab
  ])

  const updateExcludedStatus = (data: Administration | Observation, excluded: boolean) => {
    if (data instanceof Administration) {
      administrationsStore.editAdministration(
        data.id,
        props.patientId,
        props.course.id,
        data.attributes.molecule,
        data.attributes.amount,
        data.attributes.infusionLength?.value || null,
        data.attributes.administeredAt.value,
        !excluded
      )
    }

    if (data instanceof Observation) {
      if (
        data.attributes.observationType.name === 'INR' &&
        data.attributes.amount.unit &&
        data.attributes.observationType.unit
      ) {
        data.attributes.amount.unit.name = 'N/A'
        data.attributes.observationType.unit.name = 'N/A'
      }

      observationsStore.editObservation(
        data.id,
        props.patientId,
        props.course.id,
        data.attributes.observationType,
        data.attributes.amount,
        data.attributes.observedAt.value,
        !excluded
      )
    }
  }

  const administrationActionData = (admin: Administration): ITooltipMenuData[] => {
    const administrationData: ITooltipMenuData[] = [
      {
        id: 1,
        value: 'Edit this dose',
        onSelect: () => {
          setAdminToEdit(admin)
          setShowEditAdminModal(true)
        },
        actionType: 'edit'
      },
      {
        id: 2,
        value: admin.attributes.excludeFromCalculations ? 'Include in calculations' : 'Exclude from calculations',
        onSelect: () => {
          updateExcludedStatus(admin, admin.attributes.excludeFromCalculations)
        },
        actionType: admin.attributes.excludeFromCalculations ? 'include' : 'cancel'
      }
    ]

    if (admin.attributes.source === 'external') {
      return administrationData
    }

    return administrationData.concat({
      id: 3,
      value: 'Delete dose',
      onSelect: () => {
        setAdminToDelete(admin)
        setShowDeleteModal(true)
      },
      actionType: 'delete'
    })
  }

  const observationActionData = (obs: Observation): ITooltipMenuData[] => {
    const observationData: ITooltipMenuData[] = [
      {
        id: 1,
        value: 'Edit this observation',
        onSelect: () => {
          setObservationToEdit(obs)
          setShowEditObservationModal(true)
        },
        actionType: 'edit'
      },
      {
        id: 2,
        value: obs.attributes.excludeFromCalculations ? 'Include in calculations' : 'Exclude from calculations',
        onSelect: () => {
          updateExcludedStatus(obs, obs.attributes.excludeFromCalculations)
        },
        actionType: obs.attributes.excludeFromCalculations ? 'include' : 'cancel'
      }
    ]

    if (obs.attributes.source === 'external') {
      return observationData
    }

    return observationData.concat({
      id: 3,
      value: 'Delete observation',
      onSelect: () => {
        setObsToDelete(obs)
        setShowDeleteModal(true)
      },
      actionType: 'delete'
    })
  }

  const deleteAdminModalMessage: JSX.Element = (
    <>
      <div className='co-h6 d-flex'>
        <div className='d-inline-block mb-2'>
          {adminToDelete
            ? formatToDisplayDate(adminToDelete.attributes.administeredAt.value, props.hospitalTimezone)
            : null}
        </div>
        <div className='ml-2 d-inline-block'>
          {adminToDelete
            ? adminToDelete.attributes.amount.value.toString() + ' ' + adminToDelete.attributes.amount.unit!.name
            : null}
        </div>
      </div>
      <div className='mt-2'>The selected dose will be permanently deleted. This action cannot be undone.</div>
    </>
  )

  const deleteObsModalMessage: JSX.Element = (
    <>
      <div className='co-h6 d-flex'>
        <div className='d-inline-block mb-2'>
          {obsToDelete ? observationTypes.get(obsToDelete.attributes.observationType.id)?.name : null}
        </div>
        <div className='ml-2 d-inline-block'>
          {obsToDelete ? formatToDisplayDate(obsToDelete.attributes.observedAt.value, props.hospitalTimezone) : null}
        </div>
        <div className='ml-2 d-inline-block'>
          {obsToDelete
            ? obsToDelete.attributes.amount.value.toString() + ' ' + obsToDelete.attributes.amount.unit?.name
              ? obsToDelete.attributes.amount.unit?.name
              : ''
            : null}
        </div>
      </div>
      <div className='mt-2'>The selected observation will be permanently deleted. This action cannot be undone.</div>
    </>
  )

  const deleteModal = (): JSX.Element => {
    if (adminToDelete) {
      return (
        <Modal
          show={showDeleteModal}
          onHide={() => {
            setShowDeleteModal(false)
            setAdminToDelete(null)
          }}
        >
          <ConfirmModal
            confirmationType='delete'
            entityType='Dose'
            onCancel={() => {
              setAdminToDelete(null)
              setShowDeleteModal(false)
            }}
            onConfirm={() => handleDelete(adminToDelete.id)}
            title='Delete Dose'
            message={deleteAdminModalMessage}
            cancelButtonLabel='Cancel'
            confirmButtonLabel='Delete'
          />
        </Modal>
      )
    }

    return (
      <Modal
        show={showDeleteModal}
        onHide={() => {
          setShowDeleteModal(false)
          setObsToDelete(null)
        }}
      >
        <ConfirmModal
          confirmationType='delete'
          entityType='Observation'
          onCancel={() => {
            setObsToDelete(null)
            setShowDeleteModal(false)
          }}
          onConfirm={() => obsToDelete && handleDelete(obsToDelete.id)}
          title='Delete Observation'
          message={deleteObsModalMessage}
          cancelButtonLabel='Cancel'
          confirmButtonLabel='Delete'
        />
      </Modal>
    )
  }

  const handleDelete = async (objectId: string) => {
    if (adminToDelete) {
      await administrationsStore.deleteAdministration(props.patientId, props.course.id, objectId)

      if (administrationsStore.loadState === 'updateError') {
        showErrorToast(administrationsStore.error || 'Failed to delete dose')

        return
      }

      // ensures that duplicates all update simultaneously.
      await administrationsStore.fetchAdminsForPatientCourse(props.patientId, props.course.id)

      setAdminToDelete(null)
      showSuccessToast('Dose deleted')
      setShowDeleteModal(false)

      // Reload observations after admins have been updated
      await observationsStore.fetchObservationsForPatientCourse(props.patientId, props.course.id)
    }

    if (obsToDelete) {
      await observationsStore.deleteObservation(props.patientId, props.course.id, objectId)
      if (observationsStore.loadState === 'updateError') {
        showErrorToast(observationsStore.error || 'Failed to delete observation')

        return
      }

      // ensures that duplicates all update simultaneously.
      await observationsStore.fetchObservationsForPatientCourse(props.patientId, props.course.id)

      setObsToDelete(null)
      showSuccessToast('Observation deleted')
      setShowDeleteModal(false)
    }
  }

  const displayStatusIndicator = (display: boolean, manualOrEHREdited?: boolean) => {
    if (!display || window.env.VENDOR_MODE === 'standalone') {
      return <div className='pl-1 d-flex align-items-center'/>
    }

    return (
      <div
        data-testid={manualOrEHREdited ? 'invalid-red-indicator' : 'valid-green-indicator'}
        className='pl-1 pr-1 d-flex align-items-center'
      >
        <Icons.SolidCircle height={8} width={8} stroke={manualOrEHREdited ? INVALID_RED : VALID_GREEN} />
      </div>
    )
  }

  const displayExcludedIndicator = (record: Administration | Observation): JSX.Element => {
    if (record.attributes.excludedReason) {
      const tooltipId = `excluded-reason-${record.type}-${record.id}-tip`

      return (
        <>
          <Tooltip className='center-tooltip-text' id={tooltipId} place='top'>
            {record.attributes.excludedReason}
          </Tooltip>

          <div data-testid='excluded-indicator' className='d-inline-block pl-1' data-tooltip-id={tooltipId}>
            <Icons.Excluded />
          </div>
        </>
      )
    }

    return (
      <div data-testid='excluded-indicator' className='d-inline-block pl-1'>
        <Icons.Excluded />
      </div>
    )
  }

  const displayDuplicateIndicator: JSX.Element = (
    <div data-testid='duplicate-indicator' className='d-inline-block pl-1'>
      <Icons.Duplicate />
    </div>
  )

  const displayWarningIndicator: JSX.Element = (
    <>
      <Tooltip className='center-tooltip-text' id='recDuringInfTip' place='top'>
        Level recorded during dose infusion.
      </Tooltip>
      <div data-testid='warning-indicator' className='warning-indicator' data-tooltip-id='recDuringInfTip'>
        <Icons.Alert width={14} />
      </div>
    </>
  )

  const lastPredictedOutcomeTooltip: JSX.Element = (
    <div className='d-flex'>
      <Tooltip className='center-tooltip-text' id='registerTip' place='top'>
        Predicted outcomes for the most recent <br />
        dose are estimates, as the exact time of <br />
        next dose is unknown.
      </Tooltip>

      <sup>*</sup>
      <div className='predicted-outcomes-info-icon' data-tooltip-id='registerTip'>
        <Icons.Info />
      </div>
    </div>
  )

  const getAdminAmountDisplay = (admin: IAdministration) => {
    const amount = admin.attributes.receivedAmount
      ? admin.attributes.receivedAmount
      : admin.attributes.amount.value.toString()

    const units = admin.attributes.receivedUnits ? admin.attributes.receivedUnits : admin.attributes.amount.unit!.name

    return (
      <span className='d-inline-block'>
        {amount + ' ' + units}
        {admin.attributes.excludeFromCalculations ? displayExcludedIndicator(admin as Administration) : null}
      </span>
    )
  }

  const getObsAmountDisplay = (observation: Observation) => {
    const amount = observation.attributes.receivedAmount
      ? observation.attributes.receivedAmount
      : observation.attributes.amount.value.toString()

    const units = observation.attributes.receivedUnits
      ? observation.attributes.receivedUnits
      : observation.attributes.amount.unit!.name

    return (
      <span className='d-inline-block'>
        {!observation.attributes.amount.unit?.name ? amount : amount + ' ' + units}
        {observation.attributes.excludeFromCalculations ? displayExcludedIndicator(observation) : null}
      </span>
    )
  }

  const formatAdministrations = (admins: ObservableMap<string, Administration>): IRowElement[] => {
    const adminsArr = [...admins.values()]
    const sortedAdmins = sortAdministrationsByDateAsc(adminsArr)

    //reverse array and then find the first item which is not excluded to determine when the info icon should be displayed

    const adminsReversed = sortedAdmins.reverse()
    const finalIncludedIndex = adminsReversed.findIndex((admin) => !admin.attributes.excludeFromCalculations)
    const modelResults =
      dosingRecommendationStore.dosingRecommendation[props.selectedSimulationPanelTab]?.attributes.modelResults
    const displayPredictedInfoIcon = !(
      modelResults?.indPop?.doseRecommendation ||
      modelResults?.customTarget?.doseRecommendation ||
      modelResults?.customDose?.doseRecommendation
    )

    return sortedAdmins.map((admin, i) => {
      const recordColumns: IColumnElement[] = [
        {
          name: 'Administration Type',
          className: 'type-col-width-adjust',
          element: (
            <span className='d-flex'>
              {displayStatusIndicator(
                true,
                admin.attributes.source === 'manual' ||
                  (admin.attributes.source === 'external' && !!admin.attributes.edited)
              )}
              <div
                className={classnames('admin-type-subtitle', {
                  //the red/blue indicators for FHIR data don't exist here so there is more space to work with
                  'extra-width': window.env.VENDOR_MODE === 'standalone',
                  'overflow-ellipsis': admin.attributes.molecule.name.length > maxTypeStringLength
                })}
                data-tooltip-id={`recorded-course-data-type-tooltip-${admin.id}`}
              >
                {admin.attributes.molecule.name.length > maxTypeStringLength && (
                  <Tooltip className='type-tooltip' id={`recorded-course-data-type-tooltip-${admin.id}`} place='top'>
                    {admin.attributes.molecule.name}
                  </Tooltip>
                )}
                {props.course.attributes.administrationTypes.length > 1 ? admin.attributes.molecule.name : 'Dose'}
              </div>
            </span>
          ),
          text: props.course.attributes.administrationTypes.length > 1 ? admin.attributes.molecule.name : 'Dose'
        },
        {
          name: 'Time Administered',
          className: 'time-col-width-adjust',
          element: (
            <span
              className={classnames({
                'duplicate-entry-color': admin.attributes.duplicate && !admin.attributes.excludeFromCalculations
              })}
            >
              {formatToDisplayDate(admin.attributes.administeredAt.value, props.hospitalTimezone)}
              {admin.attributes.duplicate && !admin.attributes.excludeFromCalculations
                ? displayDuplicateIndicator
                : null}
            </span>
          ),
          text: admin.attributes.administeredAt.value
        },
        {
          name: 'Amount',
          className: 'amount-col-width-adjust',
          element: getAdminAmountDisplay(admin)
        },
        {
          name: 'Inf. Length',
          className: 'infusion-col-width-adjust',
          text:
            admin.attributes.infusionLength?.value === undefined || admin.attributes.infusionLength?.value === 0
              ? '—'
              : admin.attributes.infusionLength?.value + ' h'
        },
        {
          name: '',
          element:
            // Only display the action dropdown if the record is includable
            admin.attributes.excludeFromCalculations && admin.attributes.excludedReason ? (
              <></>
            ) : (
              <div data-testid='ellipsis-icon'>
                <TooltipMenu
                  button={
                    <ListButton
                      size='cm'
                      disabled={
                        !!courseStore.course?.attributes.courseArchived || !!courseStore.course?.attributes.isReadOnly
                      }
                    >
                      <Icons.Ellipsis />
                    </ListButton>
                  }
                  data={administrationActionData(admin as Administration)}
                  alignRight={true}
                  chevronOffset={10}
                />
              </div>
            )
        }
      ]

      let predictedColumns: IColumnElement[] = [
        {
          name: isWarfarinMolecule(props.course.attributes.drugModel.molecule.name) ? 'Max. INR' : 'Peak',
          className: classnames(
            'computed-historical-outcome-cell',
            'computed-outcome-border',
            'predicted-left-col-width-adjust'
          ),
          element: (
            <>
              {historicalOutcomes?.[admin.id]?.peak.toString() || '—'}
              {historicalOutcomes?.[admin.id]?.auc24?.toString() &&
                displayPredictedInfoIcon && i === finalIncludedIndex
                ? (
                  <div className='reposition-info-asterisk'>
                    <sup>*</sup>
                  </div>
                )
                : null}
            </>
          )
        },
        {
          name: isWarfarinMolecule(props.course.attributes.drugModel.molecule.name) ? 'Min. INR' : 'Trough',
          className: classnames('computed-historical-outcome-cell', 'predicted-right-col-width-adjust'),
          element: (
            <>
              {historicalOutcomes?.[admin.id]?.trough.toString() || '—'}
              {historicalOutcomes?.[admin.id]?.trough?.toString() &&
                displayPredictedInfoIcon &&
                i === finalIncludedIndex &&
                lastPredictedOutcomeTooltip}
            </>
          )
        }
      ]

      if (activePredictedTab === 'cumulative_auc') {
        predictedColumns = [
          {
            name: 'cumulative_auc',
            className: classnames(
              'computed-historical-outcome-cell',
              'computed-outcome-border',
              'predicted-left-col-width-adjust'
            ),
            element: (
              <>
                {historicalOutcomes?.[admin.id]?.cumulative_auc?.toString() || '—'}
              </>
            )
          },
          {
            name: 'percent_to_target',
            className: classnames('computed-historical-outcome-cell', 'cml-predicted-right-col-width-adjust'),
            element: (
              <>
                {
                  historicalOutcomes?.[admin.id]?.cumulative_auc_percent_to_target.toString() ?
                    `(${historicalOutcomes?.[admin.id].cumulative_auc_percent_to_target.toString()}%)` :
                    '—'
                }
                {historicalOutcomes?.[admin.id]?.cumulative_auc_percent_to_target.toString()
                  && displayPredictedInfoIcon
                  && i === finalIncludedIndex
                  && lastPredictedOutcomeTooltip}
              </>
            )
          }
        ]
      }

      if (activePredictedTab === 'auc') {
        predictedColumns = [
          {
            name: 'AUCᵀ',
            className: classnames(
              'computed-historical-outcome-cell',
              'computed-outcome-border',
              'predicted-left-col-width-adjust'
            ),
            element: (
              <>
                {historicalOutcomes?.[admin.id]?.auc?.toString() || '—'}
                {historicalOutcomes?.[admin.id]?.auc?.toString() && displayPredictedInfoIcon && i === finalIncludedIndex && (
                  <div className='reposition-info-asterisk'>
                    <sup>*</sup>
                  </div>
                )}
              </>
            )
          },
          {
            name: 'AUC24',
            className: classnames('computed-historical-outcome-cell', 'predicted-right-col-width-adjust'),
            element: (
              <>
                {historicalOutcomes?.[admin.id]?.auc24?.toString() || '—'}
                {historicalOutcomes?.[admin.id]?.auc24?.toString() &&
                  displayPredictedInfoIcon &&
                  i === finalIncludedIndex &&
                  lastPredictedOutcomeTooltip}
              </>
            )
          }
        ]
      }

      return {
        id: `admin-${admin.id}`,
        removeCheckBox: !!(admin.attributes.excludeFromCalculations && admin.attributes.excludedReason),
        disableCheckBox: !!courseStore.course?.attributes.courseArchived || !!courseStore.course?.attributes.isReadOnly,
        columns: recordColumns.concat(predictedColumns),
        className: classnames({
          'font-italic': moment
            .tz(admin.attributes.administeredAt.value, props.hospitalTimezone)
            .isAfter(moment().tz(props.hospitalTimezone))
        }),
        disabled: admin.attributes.excludeFromCalculations
      }
    })
  }

  const formatObservations = (obs: ObservableMap<string, Observation>): IRowElement[] => {
    return [...obs].map(([key, observation]) => {
      const recordColumns: IColumnElement[] = [
        {
          name: 'Observation Type',
          className: 'type-col-width-adjust',
          element: (
            <span className='d-flex align-items-center'>
              {displayStatusIndicator(
                true,
                observation.attributes.source === 'manual' ||
                  (observation.attributes.source === 'external' && !!observation.attributes.edited)
              )}
              {observation.attributes.observationType.shortName}
            </span>
          )
        },
        {
          name: 'Measurement Time',
          className: 'time-col-width-adjust',
          element: (
            <span
              className={classnames({
                'duplicate-entry-color':
                  observation.attributes.duplicate && !observation.attributes.excludeFromCalculations
              })}
            >
              <span className={classnames({ 'time-warning-text': observation.attributes.recordedDuringInfusion })}>
                {formatToDisplayDate(observation.attributes.observedAt.value, props.hospitalTimezone)}
              </span>
              {observation.attributes.recordedDuringInfusion && displayWarningIndicator}
              {observation.attributes.duplicate && !observation.attributes.excludeFromCalculations
                ? displayDuplicateIndicator
                : null}
            </span>
          ),
          text: observation.attributes.observedAt.value
        },
        {
          name: 'Amount',
          className: 'amount-col-width-adjust',
          element: getObsAmountDisplay(observation)
        },
        {
          name: 'Inf. Length',
          className: 'infusion-col-width-adjust',
          text: '—'
        },
        {
          name: '',
          element:
            // Only display the action dropdown if the record is includable
            observation.attributes.excludeFromCalculations && observation.attributes.excludedReason ? (
              <></>
            ) : (
              <div data-testid='ellipsis-icon'>
                <TooltipMenu
                  button={
                    <ListButton
                      size='cm'
                      disabled={
                        !!courseStore.course?.attributes.courseArchived || !!courseStore.course?.attributes.isReadOnly
                      }
                    >
                      <Icons.Ellipsis />
                    </ListButton>
                  }
                  data={observationActionData(observation as Observation)}
                  alignRight={true}
                  chevronOffset={10}
                />
              </div>
            )
        }
      ]

      let predictedColumns: IColumnElement[] = [
        {
          name: isWarfarinMolecule(props.course.attributes.drugModel.molecule.name) ? 'Max. INR' : 'Peak',
          text: '—',
          className: classnames(
            'computed-historical-outcome-cell',
            'computed-outcome-border',
            'predicted-left-col-width-adjust'
          )
        },
        {
          name: isWarfarinMolecule(props.course.attributes.drugModel.molecule.name) ? 'Min. INR' : 'Trough',
          text: '—',
          className: classnames('computed-historical-outcome-cell', 'predicted-right-col-width-adjust')
        }
      ]

      if (activePredictedTab === 'cumulative_auc') {
        predictedColumns = [
          {
            name: 'cumulative_auc',
            text: '—',
            className: classnames(
              'computed-historical-outcome-cell',
              'computed-outcome-border',
              'predicted-left-col-width-adjust'
            )
          },
          {
            name: 'percent_to_target',
            text: '—',
            className: classnames('computed-historical-outcome-cell', 'cml-predicted-right-col-width-adjust')
          }
        ]
      }

      if (activePredictedTab === 'auc') {
        predictedColumns = [
          {
            name: 'AUCᵀ',
            text: '—',
            className: classnames(
              'computed-historical-outcome-cell',
              'computed-outcome-border',
              'predicted-left-col-width-adjust'
            )
          },
          {
            name: 'AUC24',
            text: '—',
            className: classnames('computed-historical-outcome-cell', 'predicted-right-col-width-adjust')
          }
        ]
      }

      return {
        id: `obs-${observation.id}`,
        removeCheckBox: !!(observation.attributes.excludeFromCalculations && observation.attributes.excludedReason),
        disableCheckBox: !!courseStore.course?.attributes.courseArchived || !!courseStore.course?.attributes.isReadOnly,
        columns: recordColumns.concat(predictedColumns),
        className: classnames({
          'font-italic': moment
            .tz(observation.attributes.observedAt.value, props.hospitalTimezone)
            .isAfter(moment().tz(props.hospitalTimezone))
        }),
        disabled: observation.attributes.excludeFromCalculations
      }
    })
  }

  const formatFutureDialysisSessions = (dialysis: IHDData[]): IRowElement[] => {
    return dialysis.map((e, i) => {
      const recordColumns: IColumnElement[] = [
        {
          name: 'Observation Type',
          className: 'predicted-type-col-width-adjust predicted-outcome-cell',
          element: <span className='d-flex align-items-center'>{displayStatusIndicator(false)}HD</span>
        },
        {
          name: 'Measurement Time',
          className: 'time-col-width-adjust predicted-outcome-cell',
          element: <span>{formatToDisplayDate(e.hemodialysisDateTime, props.hospitalTimezone)}</span>,
          text: e.hemodialysisDateTime
        },
        {
          name: 'Amount',
          className: 'amount-col-width-adjust predicted-outcome-cell',
          element: (
            <span className='d-inline-block'>
              {e.hemodialysisDuration.value + ' ' + e.hemodialysisDuration.unit?.name}
            </span>
          )
        },
        {
          name: 'Inf. Length',
          className: 'infusion-col-width-adjust predicted-outcome-cell',
          text: '—'
        },
        {
          name: 'predicted-outcome-cell',
          className: classnames('predicted-outcome-cell')
        }
      ]

      let predictedColumns: IColumnElement[] = [
        {
          name: isWarfarinMolecule(props.course.attributes.drugModel.molecule.name) ? 'Max. INR' : 'Peak',
          text: '—',
          className: classnames(
            'computed-outcome-border',
            'computed-predicted-outcome-cell',
            'predicted-left-col-width-adjust'
          )
        },
        {
          name: isWarfarinMolecule(props.course.attributes.drugModel.molecule.name) ? 'Min. INR' : 'Trough',
          text: '—',
          className: classnames('computed-predicted-outcome-cell', 'predicted-right-col-width-adjust')
        }
      ]

      if (activePredictedTab === 'cumulative_auc') {
        predictedColumns = [
          {
            name: 'cumulative_auc',
            text: '—',
            className: classnames(
              'computed-outcome-border',
              'computed-predicted-outcome-cell',
              'predicted-left-col-width-adjust'
            )
          },
          {
            name: 'percent_to_target',
            text: '—',
            className: classnames('computed-predicted-outcome-cell', 'cml-predicted-right-col-width-adjust')
          }
        ]
      }

      if (activePredictedTab === 'auc') {
        predictedColumns = [
          {
            name: 'AUCᵀ',
            text: '—',
            className: classnames(
              'computed-outcome-border',
              'computed-predicted-outcome-cell',
              'predicted-left-col-width-adjust'
            )
          },
          {
            name: 'AUC24',
            text: '—',
            className: classnames('computed-predicted-outcome-cell', 'predicted-right-col-width-adjust')
          }
        ]
      }

      return {
        id: dialysis.length + i + 1,
        removeCheckBox: true,
        columns: recordColumns.concat(predictedColumns),
        className: classnames({
          'font-italic': moment
            .tz(e.hemodialysisDateTime, props.hospitalTimezone)
            .isAfter(moment().tz(props.hospitalTimezone))
        })
      }
    })
  }

  const formatPredictedDoses = (predicted: IPerDoseOutcome[], dosingInterval: number): IRowElement[] => {
    return predicted.map((futureDose: IPerDoseOutcome, i: number) => {
      const overflowName = props.course.attributes.administrationTypes.length
        && futureDose.administrationType?.name.length
        && futureDose.administrationType?.name.length > maxTypeStringLength

      const recordColumns: IColumnElement[] = [
        {
          name: 'Administration Type',
          element: (
            <span
              ref={i === 0 ? predictedDivRef : undefined}
              className={classnames('admin-type-subtitle extra-width', { 'overflow-ellipsis': overflowName })}
              data-tooltip-id={`recorded-course-data-type-tooltip-${futureDose.administrationId}`}
            >
              {displayStatusIndicator(false)}
              {props.course.attributes.administrationTypes.length > 1
                ? futureDose.administrationType?.name
                : `Dose ${i + 1}`}
              {overflowName && (
                <Tooltip
                  className='type-tooltip'
                  id={`recorded-course-data-type-tooltip-${futureDose.administrationId}`}
                  place='top'
                >
                  {futureDose.administrationType?.name}
                </Tooltip>
              )}
            </span>
          ),
          text: `Dose ${i + 1}`,
          className: classnames('predicted-outcome-cell', 'predicted-type-col-width-adjust')
        },
        {
          name: 'Time Administered',
          element: <span>{formatToDisplayDate(futureDose.time, props.hospitalTimezone)}</span>,
          text: futureDose.time,
          className: classnames('predicted-outcome-cell', 'time-col-width-adjust')
        },
        {
          name: 'Amount',
          text: futureDose.amount.value.toString() + ' ' + futureDose.amount.unit!.name,
          className: classnames('predicted-outcome-cell', 'amount-col-width-adjust')
        },
        {
          name: 'Inf. Length',
          text:
            futureDose?.infusionLength === undefined || futureDose?.infusionLength === 0
              ? '—'
              : futureDose.infusionLength.toString() + ' h',
          className: classnames('predicted-outcome-cell', 'infusion-col-width-adjust')
        },
        {
          name: '',
          className: classnames('predicted-outcome-cell')
        }
      ]

      let predictedColumns: IColumnElement[] = [
        {
          name: isWarfarinMolecule(props.course.attributes.drugModel.molecule.name) ? 'Max. INR' : 'Peak',
          text: futureDose.peak !== undefined ? futureDose.peak.toString() : '—',
          className: classnames(
            'computed-outcome-border',
            'computed-predicted-outcome-cell',
            'predicted-left-col-width-adjust'
          )
        },
        {
          name: isWarfarinMolecule(props.course.attributes.drugModel.molecule.name) ? 'Min. INR' : 'Trough',
          text: futureDose.trough !== undefined ? futureDose.trough.toString() : '—',
          className: classnames('computed-predicted-outcome-cell', 'predicted-right-col-width-adjust')
        }
      ]

      if (activePredictedTab === 'cumulative_auc') {
        predictedColumns = [
          {
            name: 'cumulative_auc',
            text: futureDose.cumulative_auc.toString(),
            className: classnames(
              'computed-outcome-border',
              'computed-predicted-outcome-cell',
              'predicted-left-col-width-adjust'
            )
          },
          {
            name: 'percent_to_target',
            text: `(${futureDose.cumulative_auc_percent_to_target?.toString()}%)`,
            className: classnames('computed-predicted-outcome-cell', 'cml-predicted-right-col-width-adjust')
          }
        ]
      }

      if (activePredictedTab === 'auc') {
        predictedColumns = [
          {
            name: 'AUCᵀ',
            text: futureDose.auc !== undefined ? futureDose.auc.toString() : '—',
            className: classnames(
              'computed-outcome-border',
              'computed-predicted-outcome-cell',
              'predicted-left-col-width-adjust'
            )
          },
          {
            name: 'AUC24',
            text: futureDose.auc24 !== undefined ? futureDose.auc24.toString() : '—',
            className: classnames('computed-predicted-outcome-cell', 'predicted-right-col-width-adjust')
          }
        ]
      }

      return {
        id: [administrationsStore.administrations].length + i + 1,
        removeCheckBox: true,
        columns: recordColumns.concat(predictedColumns),
        className: classnames({
          'predicted-data-separator': i === 0,
          'font-italic': moment.tz(futureDose.time, props.hospitalTimezone).isAfter(moment().tz(props.hospitalTimezone))
        })
      }
    })
  }

  const getHistoricalData = () => {
    let returnRecords: IRowElement[] = []
    if (
      activeRecordTab === 'all' &&
      ['updateError', 'loaded'].includes(administrationsStore.loadState) &&
      ['updateError', 'loaded'].includes(observationsStore.loadState)
    ) {
      returnRecords = formatAdministrations(administrationsStore.administrations).concat(
        formatObservations(observationsStore.observations)
      )
    }

    if (activeRecordTab === 'observations' && ['updateError', 'loaded'].includes(observationsStore.loadState)) {
      returnRecords = formatObservations(observationsStore.observations)
    }

    if (activeRecordTab === 'administrations' && ['updateError', 'loaded'].includes(administrationsStore.loadState)) {
      returnRecords = formatAdministrations(administrationsStore.administrations)
    }

    return returnRecords
  }

  const getHistoricalAndPredicted = () => {
    let historicalData = getHistoricalData()
    const predictedModelResults =
      dosingRecommendationStore.dosingRecommendation[props.selectedSimulationPanelTab]?.attributes.modelResults

    const modelResultsForSelectedTab = predictedModelResults
      ? getModelResults(predictedModelResults, props.selectedSimulationPanelTab, dosingRecommendationStore.loadState)
      : null

    //mapping the dialysis sessions to the correct format
    const dialysisSessions =
      predictedModelResults &&
      predictedModelResults[props.selectedSimulationPanelTab]?.drugSpecificResults?.hdScheduleParts

    if (['all', 'observations'].includes(activeRecordTab) && dialysisSessions && dialysisSessions.length !== 0) {
      historicalData = historicalData.concat(
        isMinRowsPredicted(formatFutureDialysisSessions(dialysisSessions), historicalData.length)
      )
    }

    // If there are individualized results, prioritize showing them in Recorded course data table.
    if (['all', 'administrations'].includes(activeRecordTab) && modelResultsForSelectedTab?.predictedPerDoseOutcomes) {
      return historicalData.concat(
        isMinRowsPredicted(
          formatPredictedDoses(
            modelResultsForSelectedTab.predictedPerDoseOutcomes,
            modelResultsForSelectedTab.dosingInterval
          ),
          historicalData.length
        )
      )
    }

    return isMinRowsHistorical(historicalData)
  }

  const getEmptyMessage = (): string => {
    if (activeRecordTab === 'administrations') {
      return 'No recorded doses found'
    }

    if (activeRecordTab === 'observations') {
      return 'No recorded observations found'
    }

    return 'No recorded doses or observations found'
  }

  const isMinRowsHistorical = (data: IRowElement[]): IRowElement[] => {
    if (data.length < 1) {
      return [
        {
          id: 'empty-row',
          className: 'empty-course-data-row',
          columns: [
            {
              name: 'Observation Type',
              text: getEmptyMessage(),
              className: classnames('empty-course-data-cell', 'empty-course-data-message', 'no-bottom-border')
            },
            {
              name: 'Measurement Time',
              className: classnames('empty-course-data-cell', 'no-bottom-border')
            },
            {
              name: 'Amount',
              className: classnames('empty-course-data-cell', 'no-bottom-border')
            },
            {
              name: 'Inf. Length',
              className: classnames('empty-course-data-cell', 'no-bottom-border')
            },
            {
              name: '',
              className: classnames('empty-course-data-cell', 'no-bottom-border')
            },

            {
              name: 'AUCᵀ',
              className: classnames(
                'empty-course-data-cell',
                'computed-historical-outcome-cell',
                'computed-outcome-border',
                'predicted-left-col-width-adjust',
                'no-bottom-border'
              )
            },
            {
              name: 'AUC24',
              className: classnames(
                'empty-course-data-cell',
                'computed-historical-outcome-cell',
                'predicted-right-col-width-adjust',
                'no-bottom-border'
              )
            }
          ]
        }
      ]
    }

    if (data.length < 5) {
      const rowsToCreate = 5 - data.length
      for (let i = 0; i < rowsToCreate; i++) {
        data.push({
          id: `empty-row-${i}`,
          className: 'empty-course-data-row',
          columns: [
            {
              name: 'Observation Type',
              className: classnames('type-col-width-adjust', 'no-bottom-border')
            },
            {
              name: 'Measurement Time',
              className: classnames('time-col-width-adjust', 'no-bottom-border')
            },
            {
              name: 'Amount',
              className: classnames('amount-col-width-adjust', 'no-bottom-border')
            },
            {
              name: 'Inf. Length',
              className: classnames('infusion-col-width-adjust', 'no-bottom-border')
            },
            {
              name: '',
              className: 'no-bottom-border'
            },

            {
              name: 'AUCᵀ',
              className: classnames(
                'computed-historical-outcome-cell',
                'computed-outcome-border',
                'predicted-left-col-width-adjust',
                'no-bottom-border'
              )
            },
            {
              name: 'AUC24',
              className: classnames(
                'computed-historical-outcome-cell',
                'no-bottom-border',
                { 'predicted-right-col-width-adjust': activePredictedTab !== 'cumulative_auc' },
                { 'cml-predicted-right-col-width-adjust': activePredictedTab === 'cumulative_auc' }
              )
            }
          ]
        })
      }
    }

    return data
  }

  const isMinRowsPredicted = (data: IRowElement[], historicalRowCount: number): IRowElement[] => {
    const totalRowCount = data.length + historicalRowCount
    if (totalRowCount < 5) {
      const rowsToCreate = 5 - totalRowCount
      for (let i = 0; i < rowsToCreate; i++) {
        data.push({
          removeCheckBox: true,
          id: `empty-row-${i}`,
          className: 'empty-course-data-row',
          columns: [
            {
              name: 'Administration Type',
              className: classnames(
                'predicted-outcome-cell',
                'type-col-width-adjust',
                'no-bottom-border',
                'predicted-outcome-type'
              )
            },
            {
              name: 'Time Administered',
              className: classnames('predicted-outcome-cell', 'time-col-width-adjust', 'no-bottom-border')
            },
            {
              name: 'Amount',
              className: classnames('predicted-outcome-cell', 'amount-col-width-adjust', 'no-bottom-border')
            },
            {
              name: 'Inf. Length',
              className: classnames('predicted-outcome-cell', 'infusion-col-width-adjust', 'no-bottom-border')
            },
            {
              name: '',
              className: classnames('predicted-outcome-cell', 'no-bottom-border')
            },
            {
              name: 'AUCᵀ',
              className: classnames(
                'computed-outcome-border',
                'computed-predicted-outcome-cell',
                'predicted-left-col-width-adjust',
                'no-bottom-border'
              )
            },
            {
              name: 'AUC24',
              className: classnames(
                'computed-predicted-outcome-cell',
                'predicted-right-col-width-adjust',
                'no-bottom-border'
              )
            }
          ]
        })
      }
    }

    return data
  }

  if (administrationsStore.loadState === 'loadError') {
    throw Error(administrationsStore.error || 'Not found')
  }

  if (observationsStore.loadState === 'loadError') {
    throw Error(observationsStore.error || 'Not found')
  }

  const displayPredictedUnits = (): JSX.Element => {
    if (
      administrationsStore.loadState === 'loaded' &&
      observationsStore.loadState === 'loaded' &&
      historicalSimulationStore.loadState === 'loaded'
    ) {
      if (activePredictedTab === 'auc') {
        return (
          <div className='predicted-outcomes-units'>
            <b>{!courseStore.isDocetaxelCourse() && props.limits.aucTarget.default.unit?.name}</b> calculated by DoseMeRx
          </div>
        )
      }

      if (activePredictedTab === 'cumulative_auc') {
        return (
          <div className='predicted-outcomes-units'>
            <b>{props.limits.cumulativeAUCTarget.default.unit?.name}</b> calculated by DoseMeRx
          </div>
        )
      }

      return (
        <div className='predicted-outcomes-units'>
          <b>{props.limits.peakTarget.default.unit?.name}</b> calculated by DoseMeRx
        </div>
      )
    }

    return <></>
  }

  const includeExcludeSelectedRows = async () => {
    const selectedAdmins = getAdminsFromSelectedRows(selectedRows).reduce((admins: number[], admin) => {
      if (includeExclude === 'Exclude' && !admin.attributes.excludeFromCalculations) {
        admins.push(parseInt(admin.id))
      }

      if (includeExclude === 'Include' && admin.attributes.excludeFromCalculations) {
        admins.push(parseInt(admin.id))
      }

      return admins
    }, [])

    const selectedObs = getObsFromSelectedRows(selectedRows).reduce((observations: number[], obs) => {
      if (includeExclude === 'Exclude' && !obs.attributes.excludeFromCalculations) {
        observations.push(parseInt(obs.id))
      }

      if (includeExclude === 'Include' && obs.attributes.excludeFromCalculations) {
        observations.push(parseInt(obs.id))
      }

      return observations
    }, [])

    if (selectedAdmins.length) {
      if (includeExclude === 'Include') {
        await administrationsStore.bulkIncludeAdministration(props.patientId, props.course.id, selectedAdmins)
      }

      if (includeExclude === 'Exclude') {
        await administrationsStore.bulkExcludeAdministration(props.patientId, props.course.id, selectedAdmins)
      }

      // Reload observations after admins have been updated, but only if they're not already getting
      // refreshed by include/exclude operations
      if (!selectedObs.length) {
        await observationsStore.fetchObservationsForPatientCourse(props.patientId, props.course.id)
      }
    }

    if (selectedObs.length) {
      if (includeExclude === 'Include') {
        await observationsStore.bulkIncludeObservation(props.patientId, props.course.id, selectedObs)
      }
      if (includeExclude === 'Exclude') {
        await observationsStore.bulkExcludeObservation(props.patientId, props.course.id, selectedObs)
      }
    }

    setSelectedRows({})
    onSelectedRows({})
  }

  const displayIncludeExcludeButton = () => {
    const label = (
      <span>
        {includeExclude}&nbsp;
        <span className='recorded-course-data-buttons-count'>{selectedRowCount ? `(${selectedRowCount})` : ''}</span>
      </span>
    )

    return (
      <ActionButton
        actionType={includeExclude === 'Exclude' ? 'cancel' : 'include'}
        onClick={() => includeExcludeSelectedRows()}
        customLabel={label}
        disabled={selectedRowCount === 0}
      />
    )
  }

  const onSelectedRows = (newSelectedRows: ISelectedRows) => {
    setSelectedRows(newSelectedRows)

    const selectedAdmins = getAdminsFromSelectedRows(newSelectedRows)
    const selectedObs = getObsFromSelectedRows(newSelectedRows)

    const newSelectedRowCount = selectedAdmins.length + selectedObs.length
    setSelectedRowCount(newSelectedRowCount)

    let containsIncluded = false

    selectedAdmins.forEach((admin) => {
      if (!admin.attributes.excludeFromCalculations && !admin.attributes.excludedReason) {
        containsIncluded = true
      }
    })

    selectedObs.forEach((obs) => {
      if (!obs.attributes.excludeFromCalculations && !obs.attributes.excludedReason) {
        containsIncluded = true
      }
    })

    containsIncluded || newSelectedRowCount === 0 ? setIncludeExclude('Exclude') : setIncludeExclude('Include')

    return
  }

  const getAdminsFromSelectedRows = (rows: ISelectedRows): Administration[] => {
    const selectRowsData: Administration[] = []
    administrationsStore.administrations.forEach((admin) => {
      if (rows[`admin-${admin.id}`] && !admin.attributes.excludedReason) {
        selectRowsData.push(admin)
      }
    })

    return selectRowsData
  }

  const getObsFromSelectedRows = (rows: ISelectedRows): Observation[] => {
    const selectRowsData: Observation[] = []

    observationsStore.observations.forEach((obs) => {
      if (rows[`obs-${obs.id}`] && !obs.attributes.excludedReason) {
        selectRowsData.push(obs)
      }
    })

    return selectRowsData
  }

  data = sortAlpha(getHistoricalAndPredicted(), sortColIndex, sortColAscending)

  return (
    <>
      <AddDoseOrObservationModal
        type='dose'
        patientId={props.patientId}
        course={props.course}
        show={props.showAddAdminModal}
        setShow={props.setShowAddAdminModal}
        hospitalTimezone={props.hospitalTimezone}
      />
      <AddDoseOrObservationModal
        type='observation'
        patientId={props.patientId}
        course={props.course}
        show={showAddObsModal}
        setShow={setShowAddObsModal}
        hospitalTimezone={props.hospitalTimezone}
      />

      <div data-testid='recorded-course-data' className='content-panel-with-shadow recorded-course-data-table mb-4'>
        <div className='w-100 mb-4 d-flex align-items-center'>
          <div className='content-panel-with-shadow-title'>Recorded Course Data</div>
          <div data-testid='add-dose-div' className='ml-3'>
            <ControlButton
              disabled={!!courseStore.course?.attributes.courseArchived || !!courseStore.course?.attributes.isReadOnly}
              icon={faPlus}
              size='sm'
              onClick={() => props.setShowAddAdminModal(true)}
            >
              Add Doses
            </ControlButton>
          </div>

          <div data-testid='add-observation-div' className='ml-3'>
            <ControlButton
              disabled={!!courseStore.course?.attributes.courseArchived || !!courseStore.course?.attributes.isReadOnly}
              icon={faPlus}
              size='sm'
              onClick={() => setShowAddObsModal(true)}
            >
              Add Observations
            </ControlButton>
          </div>
        </div>
        <div className='d-flex justify-content-between w-100 mb-1'>
          <FloatingTabBar
            tabNames={recordTabs}
            activeTab={activeRecordTab}
            onSelectTab={(activeTab) => setActiveRecordTab(activeTab as TRecordFilter)}
          />
          <div className='predicted-tab-section'>
            <FloatingTabBar
              tabNames={predictedTabs}
              activeTab={activePredictedTab}
              onSelectTab={(activeTab) => setActivePredictedTab(activeTab as TPredictedFilter)}
            />
          </div>
        </div>

        {deleteModal()}
        {adminToEdit && (
          <EditDoseModal
            show={showEditAdminModal}
            setShow={setShowEditAdminModal}
            admin={adminToEdit}
            setAdmin={setAdminToEdit}
            patientId={props.patientId}
            course={props.course}
            hospitalTimezone={props.hospitalTimezone}
          />
        )}
        {observationToEdit && (
          <EditObservationModal
            show={showEditObservationModal}
            setShow={setShowEditObservationModal}
            course={props.course}
            observation={observationToEdit}
            setObservation={setObservationToEdit}
            patientId={props.patientId}
            hospitalTimezone={props.hospitalTimezone}
          />
        )}

        <div data-testid='recorded-data-list'>
          <BasicList
            header={true}
            cols={cols}
            data={data}
            selectedRows={selectedRows}
            onRowSelect={onSelectedRows}
            disableHeaderCheckBox={
              !!courseStore.course?.attributes.courseArchived || !!courseStore.course?.attributes.isReadOnly
            }
            defaultSortColumn='Time'
            defaultSortDirection='desc'
            loading={
              historicalSimulationStore.loadState === 'initial' ||
              [
                administrationsStore.loadState,
                observationsStore.loadState,
                historicalSimulationStore.loadState,
                dosingRecommendationStore.loadState
              ].includes('loading') ||
              [
                administrationsStore.loadState,
                observationsStore.loadState,
                historicalSimulationStore.loadState,
                dosingRecommendationStore.loadState
              ].includes('updating')
            }
            textIfEmpty='No data to display'
            contentClassName='recorded-course-data-content'
            headerClassName={classnames('border-outside-bottom-allowance', {
              'dotted-predicted-data-separator': predictedDataSeparator
            })}
            cellClassName='ife-basic-list-cell-height'
          />
        </div>

        {displayPredictedUnits()}

        <div className='recorded-course-data-buttons'>{displayIncludeExcludeButton()}</div>
      </div>
    </>
  )
})

export { RecordedCourseDataPanel }

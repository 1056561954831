import React, { useMemo, useEffect, useState, useCallback } from 'react'
import {
  Brush,
  CartesianGrid,
  ComposedChart,
  Label,
  Line,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import { AxisDomain } from 'recharts/types/util/types'
import {
  EMERALD,
  INPUT_GREY,
  LIGHTGREY,
  OCEAN_BLUE,
  FADED_RED,
  CHARCOAL_GREY,
  MAGENTA,
  GUNMETAL,
  LIGHT_BLUE,
  ListButton
} from '@doseme/cohesive-ui'
import moment from 'moment-timezone'
import { unitOfTime } from 'moment'
import { debounce } from 'lodash'

import {
  binarySearchForIndex,
  convertHistoricalSimulationPlotData,
  convertPlotData,
  getFormattedNeutropeniaGrades,
  xAxisTickFormatter,
  daysSincePreviousDose
} from './utils'
import { IProps } from './types'
import { ExcludedObservationMarker } from './components/ExcludedObservationMarker'
import { SmartXAxisTick } from './components/SmartXAxisTick'
import { MICLabel } from './components/MICLabel'
import { SecrPlot } from './components/SecrPlot'
import { GCSFPlot } from './components/GCSFPlot'
import { renderNeutropeniaGradeAreas, renderNeutropeniaGradeLines } from './components/NeutropeniaGrades'
import { DoseMarker } from './components/DoseMarker'
import { ANCMarker } from './components/ANCMarker'
import { WBCMarker } from './components/WBCMarker'
import { ObservationMarker } from './components/ObservationMarker'

import './index.scss'

export const DosingProfilePlot: React.FC<IProps> = (props) => {
  const yAxisUnitLabel =
    props.plotMetadata && props.plotMetadata.plotUnit
      ? `(${props.plotMetadata.plotUnit.name})`
      : ''

  const [data, secrData, dialysisData, minimum, maximum] = useMemo(
    () => {
      if (props.hasPredictedData) {
        return convertPlotData(
          props.historicalPlotPoints,
          props.predictedPlotPoints,
          props.hospitalTimezone,
          props.secrTrendData,
          props.plotObservations,
          props.plotExcludedObservations,
          props.futureDateXValue,
          props.historicalAdministrations,
          props.predictedAdministrations,
          props.plotMetadata
        )
      }

      return convertHistoricalSimulationPlotData(
        props.historicalPlotPoints,
        props.hospitalTimezone,
        props.secrTrendData,
        props.plotObservations,
        props.plotExcludedObservations,
        props.historicalAdministrations
      )
    }, [props.hasPredictedData, props.historicalPlotPoints, props.predictedPlotPoints])

  const formattedNeutropeniaGrades = useMemo(() => {
    if (props.drugSpecificData && props.drugSpecificData['54'].neutropeniaGrades) {
      return getFormattedNeutropeniaGrades(props.drugSpecificData && props.drugSpecificData['54'].neutropeniaGrades)
    }

    return []
  }, [props.drugSpecificData])

  const [startIndex, setStartIndex] = useState<number | undefined>()
  const [endIndex, setEndIndex] = useState<number | undefined>()

  const [zoomStart, setZoomStart] = useState<number | null>(null)
  const [zoomEnd, setZoomEnd] = useState<number | null>(null)
  const [indexUpdated, setIndexUpdated] = useState(false)

  useEffect(() => {
    if (data.length && props.targetStartTimeUnix && props.targetStartTimeUnix < props.futureDateXValue) {
      const newStartIndex = binarySearchForIndex(props.targetStartTimeUnix, data)

      setStartIndex(newStartIndex)
      setEndIndex(data.length - 1)
      setIndexUpdated(false)
    }
  }, [data, props.targetStartTimeUnix, props.futureDateXValue])

  const handleUpdate = useCallback(
    debounce((newIndex: any) => {
      setStartIndex(newIndex.startIndex)
      setEndIndex(newIndex.endIndex)
      setIndexUpdated(true)
    }, 200),
    []
  )

  const handleMouseDown = (e: any) => {
    if (e && e.activeLabel) {
      setZoomStart(e.activeLabel)
      setZoomEnd(e.activeLabel)
    }
  }

  const handleMouseMove = (e: any) => {
    if (zoomStart !== null) {
      setZoomEnd(e.activeLabel)
    }
  }

  const handleMouseUp = () => {
    if (zoomStart !== null && zoomEnd !== null && zoomStart !== zoomEnd) {
      const zoomDuration = Math.abs(zoomEnd - zoomStart)

      // Only allow zoom in if the gap is more than 6 hours (21600 seconds)
      if (zoomDuration >= 21600) {
        const newStartIndex = binarySearchForIndex(Math.min(zoomStart, zoomEnd), data)
        const newEndIndex = binarySearchForIndex(Math.max(zoomStart, zoomEnd), data)

        setStartIndex(newStartIndex)
        setEndIndex(newEndIndex)
        setIndexUpdated(true)
      }
    }
    setZoomStart(null)
    setZoomEnd(null)
  }

  const resetView = () => {
    if (data.length) {
      let newStartIndex = 0
      if (props.targetStartTimeUnix && props.targetStartTimeUnix < props.futureDateXValue) {
        newStartIndex = binarySearchForIndex(props.targetStartTimeUnix, data)
      }

      setStartIndex(newStartIndex)
      setEndIndex(data.length - 1)
    }

    setIndexUpdated(false)
  }

  const brushContent = (): JSX.Element | null => {
    if (data && data.length >= 3) {
      return (
        <Brush
          dataKey='time'
          height={30}
          startIndex={startIndex && data[startIndex] ? startIndex : 0}
          endIndex={endIndex && data[endIndex] ? endIndex : data.length - 1}
          fill={INPUT_GREY}
          stroke={CHARCOAL_GREY}
          tickFormatter={(value: number) => {
            return xAxisTickFormatter(value, props.hospitalTimezone)
          }}
          onChange={handleUpdate}
          ariaLabel='Brush' // Prevents a recharts bug (https://github.com/recharts/recharts/issues/2093#issuecomment-2098161840)
        />
      )
    }

    return null
  }

  const CustomTooltip = ({ payload, label }: any) => {
    if (!payload) {
      return null
    }

    let indData: any = []
    let popData: any = []
    let custData: any = []
    let guideData: any = []

    payload.forEach((e: any) => {
      if (!e) return // Skip this iteration if data point is falsy

      if (e.name === 'Population') {
        if (e['payload']['population_historical']) {
          popData.push(e['payload']['population_historical'])
        }
        if (e['payload']['population_predicted']) {
          popData.push(e['payload']['population_predicted'])
        }
      }

      if (e.name === 'Individualized') {
        if (e['payload']['individualized_historical']) {
          indData.push(e['payload']['individualized_historical'])
        }
        if (e['payload']['individualized_predicted']) {
          indData.push(e['payload']['individualized_predicted'])
        }
      }

      if (e.name === 'Custom') {
        if (e['payload']['custom_predicted']) {
          custData.push(e['payload']['custom_predicted'])
        }
      }

      if (e.name === 'Guideline') {
        if (e['payload']['guideline_predicted']) {
          guideData.push(e['payload']['guideline_predicted'])
        }
      }
    })

    let timeLabel = xAxisTickFormatter(label, props.hospitalTimezone)

    // Add day of cycle for docetaxel (cycle begins again for each dose)
    if (props.docetaxelPlot && props.historicalAdministrations) {
      const day = daysSincePreviousDose(
        label,
        props.historicalAdministrations,
        props.predictedAdministrations,
        'Docetaxel'
      ) + 1
      timeLabel = `${timeLabel} (day ${day} of cycle)`
    }

    return (
      <div className='custom-tooltip-outer'>
        <div className='time-title'>{timeLabel}</div>
        {popData.length > 0 && (
          <div className='population-value'>
            Population:&nbsp;
            <b>
              {popData} {yAxisUnitLabel.substr(1).slice(0, -1)}
            </b>
          </div>
        )}
        {indData.length > 0 && (
          <div className='individualized-value'>
            Individualized:&nbsp;
            <b>
              {indData} {yAxisUnitLabel.substr(1).slice(0, -1)}
            </b>
          </div>
        )}
        {custData.length > 0 && (
          <div className='custom-value'>
            Custom:&nbsp;
            <b>
              {custData} {yAxisUnitLabel.substr(1).slice(0, -1)}
            </b>
          </div>
        )}
        {guideData.length > 0 && (
          <div className='guideline-value'>
            Guideline:&nbsp;
            <b>
              {guideData} {yAxisUnitLabel.substr(1).slice(0, -1)}
            </b>
          </div>
        )}
      </div>
    )
  }

  const dialysisContent = (): (JSX.Element | undefined)[] | null => {
    if (dialysisData && dialysisData.length > 0) {
      return dialysisData.map((dialysis, key) => {
        if (['seconds', 'minutes', 'hours', 'days'].includes(dialysis.amount.unit.name)) {
          const x1 = moment(dialysis.time).unix()
          const x2 = moment(dialysis.time)
            .add(dialysis.amount.value, dialysis.amount.unit.name as unitOfTime.DurationConstructor)
            .unix()

          return <ReferenceArea key={key} x1={x1} x2={x2} fill={LIGHT_BLUE} fillOpacity={1} ifOverflow='hidden' />
        }

        return
      })
    }

    return null
  }

  const getYDomain = (): AxisDomain => {
    // Max provides padding at the top of viewport so that the highest plot
    // points don't get in the way of the 'Future Predicted' text.
    const max = props.plotScale === 'log'
      ? maximum === 0 ? 5 : maximum * 1.5
      : maximum === 0 ? 5 : maximum * 1.08

    // log scales can't have a zero value on the y axis so we use the lowest non-zero value as min
    const min = props.plotScale === 'log'
      ? minimum === 0 ? 0.1 : minimum
      : 0

    return [min, max]
  }

  const dosingPlotContent = () => {
    return (
      <ResponsiveContainer width='99%' aspect={2.25}>
        <ComposedChart
          data={(!!props.secrTrendData || props.docetaxelPlot) && (startIndex || startIndex === 0) && endIndex
            ? data.slice(startIndex, endIndex + 1)
            : data
          }
          margin={{
            top: 5,
            right: 20,
            bottom: 10,
            left: -15
          }}
          syncId='syncPlots'
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
        >
          <XAxis
            id='dosing-plot-x-axis'
            hide={!!props.secrTrendData || props.docetaxelPlot}
            dataKey='time'
            name='name'
            type='number'
            domain={['dataMin', 'dataMax']}
            stroke={INPUT_GREY}
            // This custom tick ensures that leftmost and rightmost tick labels display within chart bounds
            tick={<SmartXAxisTick />}
            tickCount={5}
            tickFormatter={(value: number) => {
              return xAxisTickFormatter(value, props.hospitalTimezone)
            }}
          />

          <YAxis
            id='dosing-plot-y-axis'
            yAxisId={0}
            allowDataOverflow={props.plotScale === 'log'}
            unit=''
            type='number'
            domain={getYDomain()}
            scale={props.plotScale}
            label={{
              value: `Concentration ${yAxisUnitLabel}`,
              position: 'inside',
              angle: -90,
              dx: -10,
              fontWeight: 'bold'
            }}
            stroke={INPUT_GREY}
            tick={{ stroke: '#444', fill: '#444' }}
            orientation='left'
          />

          {/* Info on ifOverflow prop: https://github.com/recharts/recharts/issues/1419#issuecomment-548921845 */}
          {props.hasPredictedData && <ReferenceArea x1={props.futureDateXValue} fill={'#faf2e6'} fillOpacity={0.5} ifOverflow='hidden' />}

          {formattedNeutropeniaGrades.length &&
            renderNeutropeniaGradeAreas(formattedNeutropeniaGrades)
          }

          <CartesianGrid stroke={LIGHTGREY} strokeWidth={1} />

          {formattedNeutropeniaGrades.length &&
            renderNeutropeniaGradeLines(formattedNeutropeniaGrades)
          }

          <Tooltip content={CustomTooltip} cursor={{ strokeDasharray: '3 3' }} />

          {props.hasPredictedData && (
            <ReferenceLine x={props.futureDateXValue} stroke='#0264EF' strokeWidth={1.5}>
              <Label
                value='Future Predicted'
                position={'insideTopLeft'}
                className='dosingProfilePlotPlotReferenceLineLabelCurrentDate'
              />
            </ReferenceLine>
          )}

          {dialysisContent()}

          <Line
            name='Population'
            dataKey='population_historical'
            stroke={FADED_RED}
            strokeWidth={2.5}
            dot={false}
            activeDot={false}
            connectNulls
            type='linear'
            isAnimationActive={false}
          />

          <Line
            name='Population'
            dataKey='population_predicted'
            stroke={FADED_RED}
            strokeWidth={2.5}
            strokeDasharray='12 8'
            dot={false}
            activeDot={false}
            connectNulls
            type='linear'
            legendType='none'
            hide={!props.showPopulationPredictedPlot}
            isAnimationActive={false}
          />

          <Line
            name='Individualized'
            dataKey='individualized_historical'
            stroke={OCEAN_BLUE}
            strokeWidth={2.5}
            dot={false}
            activeDot={false}
            connectNulls
            type='linear'
            isAnimationActive={false}
          />

          <Line
            name='Individualized'
            dataKey='individualized_predicted'
            stroke={OCEAN_BLUE}
            strokeWidth={2.5}
            strokeDasharray='12 8'
            dot={false}
            activeDot={false}
            connectNulls
            type='linear'
            legendType='none'
            hide={!props.showIndividualPredictedPlot}
            isAnimationActive={false}
          />

          <Line
            name='Custom'
            dataKey='custom_predicted'
            stroke={EMERALD}
            strokeWidth={2.5}
            strokeDasharray='12 8'
            dot={false}
            activeDot={false}
            connectNulls
            type='linear'
            legendType='none'
            hide={!props.showCustomizedPredictedPlot}
            isAnimationActive={false}
          />

          <Line
            name='Guideline'
            dataKey='guideline_predicted'
            stroke={MAGENTA}
            strokeWidth={2.5}
            strokeDasharray='12 8'
            dot={false}
            activeDot={false}
            connectNulls
            type='linear'
            legendType='none'
            hide={!props.showGuidelinePredictedPlot}
            isAnimationActive={false}
          />

          <Line
            name='Dose'
            dataKey='population_historical_dose'
            dot={<DoseMarker markerColour={FADED_RED} />}
            stroke='none'
            isAnimationActive={false}
            activeDot={false}
          />

          <Line
            name='Dose'
            dataKey='population_predicted_dose'
            dot={<DoseMarker markerColour={FADED_RED} />}
            stroke='none'
            isAnimationActive={false}
            activeDot={false}
            hide={!props.showPopulationPredictedPlot}
          />

          <Line
            name='Dose'
            dataKey='individualized_historical_dose'
            dot={<DoseMarker markerColour={OCEAN_BLUE} />}
            stroke='none'
            isAnimationActive={false}
            activeDot={false}
          />

          <Line
            name='Dose'
            dataKey='individualized_predicted_dose'
            dot={<DoseMarker markerColour={OCEAN_BLUE} />}
            stroke='none'
            isAnimationActive={false}
            activeDot={false}
            hide={!props.showIndividualPredictedPlot}
          />

          <Line
            name='Dose'
            dataKey='custom_predicted_dose'
            dot={<DoseMarker markerColour={EMERALD} />}
            stroke='none'
            isAnimationActive={false}
            activeDot={false}
            hide={!props.showCustomizedPredictedPlot}
          />

          <Line
            name='Dose'
            dataKey='guideline_predicted_dose'
            dot={<DoseMarker markerColour={MAGENTA} />}
            stroke='none'
            isAnimationActive={false}
            activeDot={false}
            hide={!props.showGuidelinePredictedPlot}
          />

          <Line
            name='Level'
            dataKey='observation.amount.value'
            dot={<ObservationMarker />}
            stroke='none'
            isAnimationActive={false}
            activeDot={false}
          />

          {props.showExcludedLevels &&
            <Line
              name='Excluded Level'
              dataKey='excluded_observation.amount.value'
              dot={<ExcludedObservationMarker />}
              stroke='none'
              isAnimationActive={false}
              activeDot={false}
            />
          }

          <Line
            name='ANC'
            dataKey='ANC.amount.value'
            dot={<ANCMarker />}
            stroke='none'
            isAnimationActive={false}
            activeDot={false}
          />

          <Line
            name='WBC'
            dataKey='WBC.amount.value'
            dot={<WBCMarker />}
            stroke='none'
            isAnimationActive={false}
            activeDot={false}
          />

          {!props.secrTrendData && !props.docetaxelPlot && brushContent()}

          {props.micData && props.micData.amount.unit && data[data.length - 1]
            ? MICDataLabel(
              props.micData.amount.value.toString(),
              props.micData.amount.unit.name || ''
            )
            : null}

          {zoomStart !== null && zoomEnd !== null && (
            <ReferenceArea
              x1={Math.min(zoomStart, zoomEnd)}
              x2={Math.max(zoomStart, zoomEnd)}
              strokeOpacity={0.3}
            />
          )}
        </ComposedChart>
      </ResponsiveContainer>
    )
  }

  const MICDataLabel = (amount: string, unitLabel: string) => {
    return (
      <ReferenceLine id='mic-line' y={amount} stroke={GUNMETAL} strokeWidth={1.5} label={<MICLabel unit={unitLabel} amount={amount} />} />
    )
  }

  return (
    <div data-testid='dosing-profile-plot-graph' className='dosing-profile-plot-graph'>
      {dosingPlotContent()}
      {props.docetaxelPlot && (
        <GCSFPlot
          data={data}
          brushContent={brushContent()}
          hospitalTimezone={props.hospitalTimezone}
          historicalAdministrations={props.historicalAdministrations}
          predictedAdministrations={props.predictedAdministrations}
        />
      )}

      {indexUpdated && (
        <ListButton size='sm' onClick={resetView} className='dosing-profile-plot-reset-button'>
          Reset view
        </ListButton>
      )}

      {props.secrTrendData && (
        <SecrPlot
          secrTrendData={props.secrTrendData}
          startIndex={startIndex}
          endIndex={endIndex ? Math.min(endIndex, data.length - 1) : undefined}
          data={data}
          secrData={secrData}
          brushContent={brushContent()}
          hospitalTimezone={props.hospitalTimezone}
        />
      )}
    </div>
  )
}
